import React, { useEffect, useRef, createRef } from 'react';
import {
  Image,
  ImageSourcePropType,
  ListRenderItemInfo,
  ScrollView,
  View,
  ImageStyle,
  Platform,
  Linking,
} from 'react-native';
import {
  Button,
  Divider,
  List,
  StyleService,
  Text,
  useStyleSheet,
  Spinner,
  Icon,
  IconElement,
  Layout,
  withStyles,
  TopNavigationAction,
  OverflowMenu,
  MenuItem,
} from '@ui-kitten/components';
import { Avatar } from 'react-native-elements';
import StarRatingComponent from 'react-star-rating-component';
import { Rating } from 'react-native-ratings';
import moment from 'moment';
import { DetailsList } from './extra/details-list.component';
import { CategoryList } from './extra/category-list.component';
import { moviestore } from '../moviestore';
import { observer } from 'mobx-react-lite';
import { KeyboardAvoidingView } from '../../loginscreen/extra/3rd-party';
import { CommentList } from '../../commentsection/commentlist';
import { profilestore } from '../../profiletab/profilestore';
import { TouchableOpacity } from 'react-native-gesture-handler';
import netflixlogo from './assets/netflix-logo.jpeg';
import primevideologo from './assets/primevideo-logo.jpeg';
import disneypluslogo from './assets/disneyplus-logo.jpeg';
import { CustomImageCarousel } from './customimagecarousel';
import ImageNotFound from './assets/imagenotfound.png';
import imdbicon from './assets/imdbicon.png';
import rottentomatoesicon from './assets/rottentomatoesicon.png';
import ratingboxstar from '../assets/airbnb-star-selected.png';
import { ratingstore } from '../../ratingscreen/ratingstore';
import { withTranslation } from 'react-i18next';

const keyboardOffset = (height: number): number | undefined =>
  Platform.select({
    android: 0,
    ios: height,
  });

const MenuIcon = (props: any) => <Icon {...props} name="more-vertical" />;
const DeleteIcon = (props: any) => <Icon {...props} name="trash-outline" />;

export const DetailScreen = withTranslation()(
  observer(
    ({ navigation, route, theme, t }: any): React.ReactElement => {
      const store = moviestore;
      const styles = useStyleSheet(themedStyles);
      useEffect(() => {
        const initStore = async () => {
          if (route.params && route.params.id && route.params.media_type) {
            await store.loadTitle(route.params.id, route.params.media_type);
          }
        };
        initStore();
      }, [route.params]);
      console.log(route.params);
      const mediaType =
        route.params.media_type === 'movie'
          ? t('moviedetail.movie')
          : route.params.media_type === 'tv'
          ? t('moviedetail.tv')
          : 'unkowntype';
      const media_type = route.params.media_type;
      const { title } = store;
      return (
        <KeyboardAvoidingView style={styles.container} offset={keyboardOffset}>
          {store.titleLoaded ? (
            <>
              <ScrollView style={styles.container} contentContainerStyle={styles.contentContainer}>
                <Image
                  style={styles.primaryImage}
                  source={
                    title?.poster_path
                      ? {
                          uri: `https://image.tmdb.org/t/p/w440_and_h660_face/${title.poster_path}`,
                        }
                      : ImageNotFound
                  }
                />
                <Text style={styles.titleLabel} category="h6">
                  {title?.name || title?.title}
                </Text>
                <CategoryList
                  style={styles.categoryList}
                  data={title?.genres.map((genre) => genre.name)}
                />
                <Divider style={{ marginTop: 20 }} />
                <DetailsList
                  style={styles.detailsList2}
                  data={[
                    title?.imdb_vote_average && {
                      title: (
                        <Image
                          style={{ width: 40, height: 18, borderRadius: 5 }}
                          source={imdbicon}
                        />
                      ),
                      description: (
                        <>
                          <Text category="h6">{title?.imdb_vote_average}</Text>
                          <Text category="p2">({title?.imdb_vote_count})</Text>
                        </>
                      ),
                      onPress: () =>
                        Linking.openURL(
                          `https://www.imdb.com/title/${
                            media_type === 'tv' ? title.external_ids.imdb_id : title.imdb_id
                          }`
                        ),
                    },
                    store.ratings && {
                      title: (
                        <View style={{ flexDirection: 'row' }}>
                          <Text category="label">RATING</Text>
                          <Text category="label" status="primary">
                            BOX
                          </Text>
                        </View>
                      ),
                      description: (
                        <Text category="h6">
                          <Image source={ratingboxstar} style={{ width: 15, height: 15 }} />{' '}
                          {title?.movierater_vote_average || '-'} / 10
                        </Text>
                      ),
                    },
                    title?.rotten_tomatoes_vote_average && {
                      title: (
                        <Image
                          style={{ width: 70, height: 20, alignSelf: 'center' }}
                          source={rottentomatoesicon}
                        />
                      ),
                      description: <Text category="h6">{title?.rotten_tomatoes_vote_average}</Text>,
                      onPress: () =>
                        title.rotten_tomatoes_id &&
                        Linking.openURL(
                          `https://www.rottentomatoes.com/m/${title.rotten_tomatoes_id}`
                        ),
                    },
                  ]}
                />
                <Divider />
                <DetailsList
                  style={styles.detailsList2}
                  // eslint-disable-next-line no-sparse-arrays
                  data={[
                    {
                      title: t('moviedetail.year'),
                      description: moment(title?.release_date).format('YYYY'),
                    },
                    { title: t('moviedetail.type'), description: mediaType },
                    media_type === 'movie' && {
                      title: t('moviedetail.runtime'),
                      description: title?.runtime + ' min',
                    },
                    media_type === 'tv' && {
                      title: t('moviedetail.season'),
                      description: `${title && title.seasons && title.seasons.length}`,
                    },
                    ,
                  ]}
                />
                <Divider style={{ marginBottom: 10 }} />
                {title.offers && title?.offers.length > 0 && (
                  <>
                    <Text style={styles.sectionLabel} category="s1">
                      {t('moviedetail.streamingportals')}
                    </Text>
                    <List
                      data={title?.offers}
                      horizontal={true}
                      style={{ marginLeft: 20 }}
                      renderItem={({ item }) => (
                        <TouchableOpacity
                          activeOpacity={0.5}
                          onPress={() => Linking.openURL(item.urls.standard_web)}>
                          <Image
                            source={
                              item.provider === 'netflix'
                                ? netflixlogo
                                : item.provider === 'disneyplus'
                                ? disneypluslogo
                                : item.provider === 'primevideo'
                                ? primevideologo
                                : ''
                            }
                            style={{ borderRadius: 10, width: 60, height: 60 }}
                          />
                        </TouchableOpacity>
                      )}
                    />
                  </>
                )}
                <Text style={styles.sectionLabel} category="s1">
                  {t('moviedetail.description')}
                </Text>
                <Text style={styles.descriptionLabel} appearance="hint">
                  {title?.overview}
                </Text>
                <Text style={styles.sectionLabel} category="s1">
                  {t('moviedetail.media')}
                </Text>
                <CustomImageCarousel id={title?.id} movieType={route.params.media_type} />
                <Layout>
                  <Divider style={{ marginBottom: 5 }} />
                  <Text style={styles.sectionLabel} category="s1">
                    {t('moviedetail.reviews')}
                  </Text>
                  <View
                    style={{
                      flex: 1,
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginBottom: 15,
                    }}>
                    <Text category="h1">{title?.movierater_vote_average}</Text>
                    {Platform.OS === 'web' ? (
                      <StarRatingComponent
                        name={'Rating3'}
                        value={title?.movierater_vote_average || 0}
                        starCount={10}
                        editing={false}
                      />
                    ) : (
                      <Rating
                        readonly={true}
                        startingValue={title?.movierater_vote_average || '0'}
                        ratingCount={10}
                        imageSize={20}
                        tintColor={profilestore.darkMode && theme['background-basic-color-1']}
                      />
                    )}
                    <Text style={[styles.sectionLabel, { marginTop: 5 }]} category="c2">
                      ({store.title?.movierater_vote_count})
                    </Text>
                  </View>
                  <Divider style={{ marginBottom: 10 }} />
                  <View
                    style={{
                      flex: 1,
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginBottom: 15,
                    }}>
                    {store.userRating && (
                      <View
                        style={{
                          position: 'absolute',
                          alignItems: 'center',
                          justifyContent: 'center',
                          top: 0,
                          right: 0,
                        }}>
                        <OverflowMenu
                          data={[{ icon: DeleteIcon, title: t('moviedetail.delete') }]}
                          visible={store.ratingMenu}
                          selectedIndex={store.ratingMenuIndex}
                          onSelect={async (index) => {
                            store.ratingMenu = false;
                            store.ratingMenuIndex = index;
                            if (store.ratingMenuIndex === 0 && store.userRating) {
                              console.log(store.userRating);
                              await ratingstore.deleteRating(store.userRating.id);
                              await store.loadTitle(route.params.id, route.params.media_type);
                            }
                          }}
                          onBackdropPress={() => (store.ratingMenu = false)}>
                          <Button
                            appearance="ghost"
                            onPress={() => {
                              store.ratingMenu = !store.ratingMenu;
                              store.ratingMenuIndex = 1;
                            }}
                            icon={MenuIcon}
                          />
                        </OverflowMenu>
                      </View>
                    )}
                    <Avatar
                      rounded={true}
                      title={`${profilestore.user?.username[0]}`}
                      source={{
                        uri: profilestore.user?.profile_picture,
                      }}
                      size={50}
                    />
                    {store.userRating ? (
                      <Text category="s1">{t('moviedetail.yourreview')}</Text>
                    ) : (
                      <Text category="s1">{t('moviedetail.writereview')}</Text>
                    )}
                    {profilestore.authUser ? (
                      <>
                        {!store.userRating && (
                          <Text style={{ marginBottom: 10 }} category="s3">
                            {t('moviedetail.sharereview')}
                          </Text>
                        )}
                        <TouchableOpacity
                          activeOpacity={0.9}
                          disabled={store.userRating ? true : false}
                          onPress={() => {
                            navigation &&
                              profilestore.authUser &&
                              navigation.navigate('RateMovie', {
                                movieId: route.params.id,
                                mediaType: route.params.media_type,
                                title: title?.title,
                              });
                          }}>
                          {Platform.OS === 'web' ? (
                            <View
                              style={{
                                alignItems: 'center',
                                flex: 1,
                                justifyContent: 'center',
                              }}>
                              <StarRatingComponent
                                name={'Rating2'}
                                value={store.userRating?.rating || 0}
                                starCount={10}
                              />
                            </View>
                          ) : (
                            <Rating
                              readonly={true}
                              startingValue={store.userRating?.rating || 0}
                              ratingCount={10}
                              fractions={0}
                              tintColor={profilestore.darkMode && theme['background-basic-color-1']}
                              imageSize={30}
                            />
                          )}
                          {store.userRating && (
                            <View>
                              <Text style={styles.descriptionLabel}>{store.userRating.text}</Text>
                            </View>
                          )}
                        </TouchableOpacity>
                      </>
                    ) : (
                      <Text category="h6">{t('moviedetail.noaccount')}</Text>
                    )}
                  </View>
                </Layout>
              </ScrollView>

              <CommentList
                style={styles.commentList}
                data={store.ratings}
                navigation={navigation}
              />
            </>
          ) : (
            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
              <Spinner size="giant" />
            </View>
          )}
        </KeyboardAvoidingView>
      );
    }
  )
);
export const MovieDetail = withStyles(DetailScreen);

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'background-basic-color-2',
  },
  commentList: {
    flex: 1,
  },
  header: {
    flexDirection: 'row',
    overflow: 'hidden',
    padding: 16,
  },
  contentContainer: {
    paddingVertical: 24,
  },
  primaryImage: {
    alignSelf: 'center',
    width: 256,
    height: 360,
    borderRadius: 8,
  },
  titleLabel: {
    alignSelf: 'center',
    textAlign: 'center',
    marginHorizontal: 64,
    marginTop: 24,
  },
  subtitleLabel: {
    justifyContent: 'center',
    alignSelf: 'center',
  },
  categoryList: {
    alignSelf: 'center',
    marginVertical: 8,
  },
  rateBar: {
    alignSelf: 'center',
    marginTop: 16,
    marginBottom: 24,
  },
  detailsList: {
    alignSelf: 'center',
    marginVertical: 24,
  },
  detailsList2: {
    alignSelf: 'center',
    marginVertical: 18,
  },
  descriptionLabel: {
    margin: 16,
  },
  imagesList: {
    overflow: 'hidden',
    paddingHorizontal: 8,
    paddingVertical: 16,
    backgroundColor: 'background-basic-color-1',
  },
  sectionLabel: {
    marginHorizontal: 16,
  },
  buyButton: {
    marginHorizontal: 16,
    marginTop: 24,
  },
});
