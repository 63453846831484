export const languages = [
  {
    name: 'de',
    display: 'German (Deutsch)',
  },
  {
    name: 'en',
    display: 'English',
  },
];
