import React, { useEffect } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { BottomNavigationTab, BottomNavigation, Icon, withStyles } from '@ui-kitten/components';
import { WatchlistNavigation } from './watchlistnavigatior';
import { HomePageNavigator } from './homepagenavigator';
import { ProfileNavigator } from './profilenavigator';
import firebase from 'firebase';
import { profilestore } from '../profiletab/profilestore';
import { SearchNavigator } from '../searchmovie/searchnavigator';
import { SafeAreaView, Platform } from 'react-native';
import { AsyncStorage } from 'react-native';
import { ThemeContext } from '../../theme-context';
import i18n from '../localization/i18n';

const { Navigator, Screen } = createBottomTabNavigator();

const PersonIcon = (props: any) => <Icon {...props} name="person-outline" />;
const HomeIcon = (props: any) => <Icon {...props} name="home-outline" />;
const BookMarkIcon = (props: any) => <Icon {...props} name="bookmark-outline" />;
const SearchIcon = (props: any) => <Icon {...props} name="search-outline" />;

const BottomTabBar = ({ navigation, state }: any) => (
  <BottomNavigation
    selectedIndex={state.index}
    onSelect={(index: any) => navigation.navigate(state.routeNames[index])}>
    <BottomNavigationTab icon={HomeIcon} />
    <BottomNavigationTab icon={SearchIcon} />
    <BottomNavigationTab icon={BookMarkIcon} />
    <BottomNavigationTab icon={PersonIcon} />
  </BottomNavigation>
);
const TabNavigator = () => (
  <Navigator tabBar={(props: any) => <BottomTabBar {...props} />}>
    <Screen name="Home" component={HomePageNavigator} />
    <Screen name="Search" component={SearchNavigator} />
    <Screen name="Watchlist" component={WatchlistNavigation} />
    <Screen name="Profile" component={ProfileNavigator} />
  </Navigator>
);

const Navigation = (props: any) => {
  const themeContext = React.useContext(ThemeContext);
  useEffect(() => {
    const checkIfLoggedIn = () => {
      firebase.auth().onAuthStateChanged(async (user) => {
        if (user) {
          profilestore.authUser = user;
          await profilestore.initUser(user);
        } else {
          profilestore.authUser = undefined;
          profilestore.user = undefined;
        }
      });
    };
    checkIfLoggedIn();
  });
  useEffect(() => {
    const initTheme = async () => {
      const isDarkMode = await AsyncStorage.getItem('@darkmode');
      if (isDarkMode === 'true') {
        profilestore.darkMode = true;
        themeContext.toggleTheme();
      }
      const language = await AsyncStorage.getItem('@language');
      if (language) {
        i18n.changeLanguage(language);
      }
    };
    initTheme();
  }, []);
  return (
    <SafeAreaView
      style={{
        flex: 1,
        backgroundColor: props.theme['background-basic-color-1'],
        paddingTop: Platform.OS === 'android' ? 25 : 0,
      }}>
      <NavigationContainer>
        <TabNavigator />
      </NavigationContainer>
    </SafeAreaView>
  );
};
export const AppNavigator = withStyles(Navigation);
