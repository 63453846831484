import React, { useEffect } from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { LoginScreen } from '../loginscreen/login';
import { SignUpScreen } from '../loginscreen/signup';
import firebase from 'firebase';
import { Spinner, Layout } from '@ui-kitten/components';
import { ProfileScreen } from '../profiletab/profile';
import { ForgotPassword } from '../loginscreen/forgotpassword';
import { PrivacyPolicy } from '../profiletab/privacypolicy';
import { LegalNotice } from '../profiletab/legalnotice';
import { Localization } from '../profiletab/localization';

const Stack = createStackNavigator();

const ProfileScreenCheck = ({ navigation }: any): React.ReactElement => {
  useEffect(() => {
    const checkIfLoggedIn = () => {
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          navigation.navigate('Profile', { user: user });
        } else {
          navigation.navigate('Login');
        }
      });
    };
    checkIfLoggedIn();
  }, []);
  return (
    <Layout
      style={{
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Spinner size="giant" />
    </Layout>
  );
};

export const ProfileNavigator = (): React.ReactElement => (
  <Stack.Navigator headerMode="none">
    <Stack.Screen name="ProfileCheck" component={ProfileScreenCheck} />
    <Stack.Screen name="Profile" component={ProfileScreen} />
    <Stack.Screen name="Login" component={LoginScreen} />
    <Stack.Screen name="SignUp" component={SignUpScreen} />
    <Stack.Screen name="ForgotPassword" component={ForgotPassword} />
    <Stack.Screen name="PrivacyPolicy" component={PrivacyPolicy} />
    <Stack.Screen name="LegalNotice" component={LegalNotice} />
    <Stack.Screen name="Localization" component={Localization} />
  </Stack.Navigator>
);
