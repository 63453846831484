import { observable } from 'mobx';
import Axios from 'axios';
export interface IVideo {
  id: number;
  results: IResult[];
}

export interface IResult {
  id: string;
  iso_639_1: string;
  iso_3166_1: string;
  key: string;
  name: string;
  site: string;
  size: number;
  type: string;
}
export interface IImages {
  backdrops: IBackdrop[];
  id: number;
  posters: IBackdrop[];
}

export interface IBackdrop {
  aspect_ratio: number;
  file_path: string;
  height: number;
  iso_639_1?: string;
  vote_average: number;
  vote_count: number;
  width: number;
}

export interface ICarouselItem {
  uri: string;
  type: 'video' | 'image';
  videoUrl?: string;
  height?: number;
  width?: number;
}

class Store {
  @observable index = 0;
  @observable loading = false;
  @observable carouselItems: ICarouselItem[] = [];
  @observable imageModal = false;

  async init(id: number, movieType: string) {
    this.carouselItems = [];
    let videos: IVideo = { id: 0, results: [] };
    let images: IImages = { backdrops: [], id: 0, posters: [] };
    this.loading = true;
    try {
      const imageResponse = await Axios.get(
        `https://nextjs-movierater.now.sh/api/media?id=${id}&movieType=${movieType}&mediaType=images`
      );
      console.log(imageResponse.data);
      if (imageResponse && imageResponse.data) {
        images = imageResponse.data;
      }
      const videoResponse = await Axios.get(
        `https://nextjs-movierater.now.sh/api/media?id=${id}&movieType=${movieType}&mediaType=videos`
      );
      if (videoResponse && videoResponse.data) {
        videoResponse.data.results.sort((result: any) => (result.iso_639_1 === 'de' ? 1 : 0));
        videos = videoResponse.data;
      }
      videos.results.map((results) =>
        this.carouselItems.push({
          type: 'video',
          uri: `https://img.youtube.com/vi/${results.key}/hqdefault.jpg`,
          videoUrl: `https://www.youtube.com/watch?v=${results.key}`,
        })
      );
      images.backdrops.concat(images.posters).map((backdrop) => {
        this.carouselItems.push({
          type: 'image',
          uri: `https://image.tmdb.org/t/p/original${backdrop.file_path}`,
          height: backdrop.height,
          width: backdrop.width,
        });
      });
    } catch (error) {
      console.log('Error loading media');
    }
    this.loading = false;
  }
}

export const carouselstore = new Store();
