import { observable } from 'mobx';
import { fsservice } from '../firebaseservice/firebaseservice';
import { IWatchlist } from './model';
import { profilestore } from '../profiletab/profilestore';
import * as Analytics from 'expo-firebase-analytics';
import { IRating } from 'src/commentsection/model';
import firebase from 'firebase';
import { ITitle } from '../model/titles';
import Axios from 'axios';
import i18next from 'i18next';
export type IMyRating = IRating & { movie: ITitle };
class Store {
  @observable myratings: IMyRating[] = [];
  @observable loaded = false;

  async initMyRatings(userId: string): Promise<void> {
    this.loaded = false;
    this.myratings = [];
    if (userId) {
      const response = await firebase
        .firestore()
        .collection('ratings')
        .where('author.userId', '==', userId)
        .get();
      if (response) {
        response.forEach((result) => this.myratings.push(result.data() as IMyRating));
        this.myratings = this.myratings.sort((a, b) => b.created_at - a.created_at);
        for (const rating of this.myratings) {
          const titleResponse = await Axios.get(
            `https://nextjs-movierater.now.sh/api/titles?titleId=${rating.movieId}&media_type=${rating.media_type}&language=${i18next.language}`
          );
          if (titleResponse?.data) {
            console.log('set movie to rating');
            rating.movie = titleResponse.data;
          }
        }
      }
    }
    await Analytics.logEvent('MyRatings', {
      name: 'Ratings',
      screen: 'profile',
      purpose: 'Opens the internal settings',
    });
    this.loaded = true;
  }
}

export const myratingsstore = new Store();
