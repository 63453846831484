export const en = {
  translation: {
    homepage: {
      headlinepopular: "WHAT'S POPULAR",
      headlineshow: 'TRENDING TV SHOWS',
      headlinemovies: 'TRENDING MOVIES',
      lastreviewed: 'RECENTLY REVIEWED',
      showmore: 'show more',
      moreinfo: 'more info',
      choice: 'CHOICE',
    },
    search: {
      searchplaceholder: 'Search for Movies or TV Shows',
      results: 'results',
    },
    changeLocalization: 'Change Language',
    profilenavigation: {
      profilenav: 'Profile',
      username: 'USERNAME',
      usernamesavemessage: 'Saving...',
      email: 'E-Mail',
      password: 'Password',
      changepassword: 'Change Password',
      createdat: 'Created At',
      reviewapp: 'Review the App',
      privacypolicy: 'Privacy Policy',
      supportapp: 'Support with a Donation',
      language: 'Language',
      contact: 'Contact',
      contactNav: {
        serviceprovider: 'Service Provider',
        contactoptions: 'Contact Options',
        email: 'E-Mail-Address',
      },
      logout: 'Logout',
      changepassworduppercase: 'CHANGE PASSWORD',
      oldpassword: 'Old Password',
      newpassword: 'New Password',
      passwordconfirmation: 'Confirm Password',
      passwordloading: 'Changing Password...',
      login: {
        sociallogin: 'Login with Social Account',
        loginor: 'OR',
        email: 'E-Mail',
        password: 'Password',
        forgotpassword: 'Forgot Password?',
        loginuppercase: 'LOGIN',
        loginquestion: 'No Account? Register now.',
      },
      register: {
        socialregister: 'Register with Social Account',
        registeror: 'OR',
        username: 'Username',
        email: 'E-Mail',
        password: 'Password',
        passwordconfirmation: 'Confirm Password',
        registeruppercase: 'REGISTER',
        registerquestion: 'Already registered? Login now.',
        privacypolicy1: 'I agree to the ',
        privacypolicy2: 'Terms of Use',
        privacypolicy3: ' and Privacy Policy',
      },
      forgotpassword: {
        reset: 'RESET PASSWORD',
        email: 'E-Mail',
        send: 'SEND',
        login: 'Login?',
      },
    },
    saved: {
      watchlist: {
        headline: 'WATCHLIST',
        empty: 'Ooops... Your Watchlist seems empty',
        loginerror: 'Create an Account to edit your Watchlist',
      },
      myratings: {
        headline: 'MY RATINGS',
        empty: 'You have no ratings. Share your opinions now with other users!',
        emptyforuser: 'This user has no ratings',
        from: 'From',
      },
    },
    moviedetail: {
      year: 'Year',
      type: 'Type',
      runtime: 'Runtime',
      streamingportals: 'Stream now',
      description: 'Description',
      media: 'Media',
      reviews: 'Reviews',
      yourreview: 'Your Review:',
      sharereview: 'Share your opinion with other Users',
      emptyreviews: 'Ooops! .. There are no Reviews',
      publicreview: 'will be posted public',
      containsspoiler: 'Contains Spoiler',
      writereview: 'Write Review',
      delete: 'Delete',
      spoiler: 'Spoiler',
      report: 'Report',
      movie: 'Movie',
      tv: 'TV Show',
      season: 'Season',
      noaccount: 'Create an Account to write a Review',
      spoilerwarning: 'This Review contains a Spoiler. Show this Review?',
      ratingerror: 'Please provide a Review.',
      charactersleft: '{{value}} characters left.',
      reports: {
        headline: 'Do you want to report the Review from {{username}}?',
        warning: 'Warning: Incorrect reports can result to account deletion.',
        spoiler: 'Contains Spoiler',
        other: 'e.g. Ad, Spam',
        infos: 'Additional Infos',
        send: 'Send',
        success: 'You have successfully reported the Review from {{username}}!',
        successsubheadline: 'We will take care of it as soon as possible',
        continue: 'Continue',
      },
    },
    subheadline: 'Review Movies and Shows',
    cancel: 'Cancel',
    save: 'Save',
  },
};
