import { observable } from 'mobx';
import firebase from 'firebase';
import { IRating } from 'src/commentsection/model';
class Store {
  @observable rating: IRating | undefined;
  @observable saving = false;
  @observable errors: string[] = [];

  async rateMovie() {
    if (this.rating) {
      this.rating.created_at = Date.now();
      await firebase.firestore().collection('ratings').add(this.rating);
    }
  }
  async deleteRating(id: string) {
    try {
      await firebase.firestore().collection('ratings').doc(id).delete();
    } catch (error) {
      console.log('Error deleting Rating');
    }
  }
}

export const ratingstore = new Store();
