import React, { useEffect } from 'react';
import { TopNavigation, TopNavigationAction, Spinner } from '@ui-kitten/components';
import { MovieDetail } from './moviedetail';
import {
  ArrowIosBackIcon,
  BookmarkIcon,
  BookmarkOutlineIcon,
  AwardIcon,
  AwardOutlineIcon,
} from '../../icons/icons';
import { observer } from 'mobx-react-lite';
import { moviestore } from '../moviestore';
import { profilestore } from '../../profiletab/profilestore';
import { View } from 'react-native';

export const MovieDetailScreen = observer(
  ({ navigation, route }: any): React.ReactElement => {
    const store = moviestore;

    const onBookmarkActionPress = async (): Promise<void> => {
      if (profilestore.authUser) {
        await store.toggleWatchlist(route.params.id, route.params.media_type);
      }
    };

    let displayFeatured = false;
    if (profilestore.authUser) {
      displayFeatured = ['YbQnqKha04XK6jBUvTCx11XCHB12', 'hf3crqmHKPNjzY2o1FDGkLRpX8B3'].includes(
        profilestore?.authUser?.uid
      );
    }

    useEffect(() => {
      const init = async () => {
        if (displayFeatured) {
          await store.checkFeatured(route.params.id);
        }
      };
      init();
    }, []);

    const renderBackAction = (): React.ReactElement => (
      <TopNavigationAction icon={ArrowIosBackIcon} onPress={navigation.goBack} />
    );

    const renderBookmarkAction = (): React.ReactElement => (
      <>
        {!store.bookMarkLoading ? (
          <>
            {displayFeatured && (
              <TopNavigationAction
                icon={store.featured ? AwardIcon : AwardOutlineIcon}
                onPress={() => store.onFeaturePress(route.params.id, route.params.media_type)}
              />
            )}
            <TopNavigationAction
              icon={store.bookMarked ? BookmarkIcon : BookmarkOutlineIcon}
              onPress={() => onBookmarkActionPress()}
            />
          </>
        ) : (
          <Spinner />
        )}
      </>
    );
    return (
      <>
        <TopNavigation
          title={store.titleLoaded ? store.title?.title : ''}
          leftControl={renderBackAction()}
          rightControls={store.titleLoaded && profilestore.authUser ? [renderBookmarkAction()] : []}
        />
        <MovieDetail navigation={navigation} route={route} />
      </>
    );
  }
);
