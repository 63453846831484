import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import {
  Text,
  List,
  Spinner,
  StyleService,
  useStyleSheet,
  TabView,
  Tab,
  Layout,
} from '@ui-kitten/components';
import { watchliststore } from './watchliststore';
import { View, Dimensions } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { MovieCard } from '../homescreen/moviecard';
import { profilestore } from '../profiletab/profilestore';
import { MyRatingsList } from '../myratings/myratingslist';
import { myratingsstore } from '../myratings/myratingsstore';
import { withTranslation } from 'react-i18next';
const ITEM_WIDTH = Dimensions.get('window').width;

export const Watchlist = withTranslation()(
  observer(
    ({ navigation, t }: any): React.ReactElement => {
      const store = watchliststore;
      const styles = useStyleSheet(themedStyle);
      useEffect(() => {
        const unsubscribe = navigation.addListener('focus', () => {
          const initStore = async () => {
            await watchliststore.initWatchlist();
            await myratingsstore.initMyRatings(profilestore.authUser?.uid);
          };
          initStore();
        });
        return unsubscribe;
      }, [navigation]);
      return (
        <Layout level="1" style={{ flex: 1 }}>
          <TabView
            style={{ flex: 1 }}
            selectedIndex={watchliststore.navIndex}
            onSelect={(index) => (watchliststore.navIndex = index)}>
            <Tab title="WATCHLIST" style={{ marginVertical: 15 }}>
              <View style={styles.container}>
                {store.loaded ? (
                  <List
                    ListEmptyComponent={
                      !profilestore.authUser ? (
                        <Text>{t('saved.watchlist.loginerror')}</Text>
                      ) : (
                        <Text>{t('saved.watchlist.empty')}</Text>
                      )
                    }
                    data={store.watchlist}
                    numColumns={3}
                    horizontal={false}
                    renderItem={(info: any) => {
                      return (
                        <TouchableOpacity
                          key={info.index}
                          activeOpacity={0.9}
                          onPress={() =>
                            navigation.navigate('MovieDetail', {
                              id: info.item.movieId,
                              media_type: info.item.mediaType,
                            })
                          }>
                          <MovieCard
                            style={{ margin: 5 }}
                            imageStyle={{
                              borderRadius: 5,
                              width: ITEM_WIDTH / 3 - 16,
                              height: ITEM_WIDTH / 2.2,
                            }}
                            poster={info.item.poster_path}
                          />
                        </TouchableOpacity>
                      );
                    }}
                  />
                ) : (
                  <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                    <Spinner size="giant" />
                  </View>
                )}
              </View>
            </Tab>
            <Tab title={t('saved.myratings.headline')} style={{ marginVertical: 15 }}>
              <MyRatingsList navigation={navigation} />
            </Tab>
          </TabView>
        </Layout>
      );
    }
  )
);

const themedStyle = StyleService.create({
  container: {
    marginHorizontal: 10,
    flex: 1,
  },
  primaryBottomBorder: {
    borderBottomWidth: 3,
    borderBottomColor: 'color-primary-default',
  },
  horizontalItem: {
    marginLeft: 2,
    marginRight: 2,
  },
  readButton: {
    width: '50%',
    marginTop: 32,
  },
  headingArticleContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    height: 320,
  },
  headingArticleTitle: {
    zIndex: 1,
    textAlign: 'center',
  },
  headingArticleDescription: {
    zIndex: 1,
  },
  item: {
    flexDirection: 'row',
  },
  itemSection: {
    flex: 1,
    padding: 16,
  },
  itemReactionsContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 24,
    marginHorizontal: -8,
  },
  itemTitle: {
    flex: 1,
  },
  iconButton: {
    paddingHorizontal: 0,
  },
});
