import { observable } from 'mobx';
import { fsservice } from '../firebaseservice/firebaseservice';
import { IWatchlist } from './model';
import { profilestore } from '../profiletab/profilestore';
import * as Analytics from 'expo-firebase-analytics';

class Store {
  @observable watchlist: IWatchlist[] = [];
  @observable loaded = false;
  @observable navIndex = 0;

  async initWatchlist(): Promise<void> {
    this.loaded = false;
    if (profilestore.authUser) {
      const response = await fsservice.getUserDocument().get();
      await Analytics.logEvent('Watchlist', {
        name: 'Watchlist',
        screen: 'profile',
        purpose: 'Opens the internal settings',
      });
      const userWatchlist = response.data()?.watchlist.sort().reverse();
      if (userWatchlist) {
        this.watchlist = userWatchlist;
      }
    }
    this.loaded = true;
  }

  async checkInWatchlist(id: string): Promise<boolean> {
    const response = await fsservice.getUserDocument().get();
    const user = response.data();
    let movieExists = null;
    if (user && user.watchlist && user.watchlist.length > 0) {
      movieExists = user.watchlist.find((movie) => movie.movieId === id);
    }
    return movieExists ? true : false;
  }
}

export const watchliststore = new Store();
