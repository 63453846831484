import React from 'react';
import { View, Dimensions, Image, Platform } from 'react-native';
import {
  Button,
  Input,
  StyleService,
  Text,
  useStyleSheet,
  Divider,
  Spinner,
  Modal,
} from '@ui-kitten/components';
import { KeyboardAvoidingView } from './extra/3rd-party';
import { observer } from 'mobx-react-lite';
import { signupstore } from './signupstore';
import {
  GoogleIcon,
  FacebookIcon,
  TwitterIcon,
  EyeIcon,
  EyeOffIcon,
  PersonIcon,
  MailIcon,
} from './extra/icons';
import { loginstore } from './loginstore';
import { BarPasswordStrengthDisplay } from 'react-native-password-strength-meter';
import { CloseIcon, CheckMarkIcon } from '../icons/icons';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { withTranslation } from 'react-i18next';
const DISPLAY_WIDTH = Dimensions.get('window').width;
export const SignUpScreen = withTranslation()(
  observer(
    ({ navigation, t }: any): React.ReactElement => {
      const styles = useStyleSheet(themedStyles);
      const store = signupstore;

      return (
        <KeyboardAvoidingView style={styles.container}>
          <View style={styles.headerContainer}>
            <Image
              style={{ height: 100, width: 100 }}
              resizeMode="contain"
              source={require('./assets/logo-white-plain.png')}
            />
            <View style={{ flexDirection: 'row' }}>
              <Text category="h4">RATING </Text>
              <Text category="h4" style={{ color: 'white' }}>
                BOX
              </Text>
            </View>
            <Text category="c2">- {t('subheadline')} -</Text>
          </View>
          <View style={styles.socialAuthContainer}>
            <Text style={styles.socialAuthHintText} appearance="hint">
              {t('profilenavigation.register.socialregister')}
            </Text>
            <View style={styles.socialAuthButtonsContainer}>
              <Button
                appearance="ghost"
                size="giant"
                status="basic"
                icon={GoogleIcon}
                disabled={Platform.OS === 'web'}
                onPress={() => loginstore.signInWithGoogleAsync()}
              />
              <Button
                appearance="ghost"
                size="giant"
                status="basic"
                icon={FacebookIcon}
                disabled={Platform.OS === 'web'}
                onPress={() => loginstore.signInWithFacebook()}
              />
              {/* <Button appearance="ghost" size="giant" status="basic" icon={TwitterIcon} /> */}
            </View>
          </View>
          <View style={styles.orContainer}>
            <Divider style={styles.divider} />
            <Text style={styles.orLabel} appearance="hint" category="h5">
              {t('profilenavigation.register.registeror')}
            </Text>
            <Divider style={styles.divider} />
          </View>
          <View style={[styles.container, styles.formContainer]}>
            <Input
              placeholder={t('profilenavigation.register.username')}
              autoCompleteType="username"
              icon={PersonIcon}
              autoCapitalize="words"
              value={store.username}
              onChangeText={(text) => (store.username = text)}
            />
            <Input
              style={styles.formInput}
              placeholder={t('profilenavigation.register.email')}
              autoCapitalize="none"
              icon={MailIcon}
              autoCompleteType="email"
              value={store.email}
              onChangeText={(text) => (store.email = text)}
            />
            <Input
              style={styles.formInput}
              placeholder={t('profilenavigation.register.password')}
              autoCompleteType="password"
              secureTextEntry={!store.passwordVisible}
              onIconPress={() => (store.passwordVisible = !store.passwordVisible)}
              icon={store.passwordVisible ? EyeIcon : EyeOffIcon}
              value={store.password}
              onChangeText={(text) => (store.password = text)}
            />
            {store.password !== '' && (
              <BarPasswordStrengthDisplay
                minLength={0}
                password={store.password}
                width={DISPLAY_WIDTH - 50}
              />
            )}
            <Input
              style={styles.formInput}
              placeholder={t('profilenavigation.register.passwordconfirmation')}
              autoCompleteType="password"
              secureTextEntry={!store.passwordVisible}
              value={store.passwordVerfification}
              icon={
                store.password !== '' && store.password === store.passwordVerfification
                  ? CheckMarkIcon
                  : CloseIcon
              }
              status={
                store.password === ''
                  ? 'basic'
                  : store.password === store.passwordVerfification
                  ? 'success'
                  : 'danger'
              }
              onChangeText={(text) => (store.passwordVerfification = text)}
            />
            {!!store.errorMessage && (
              <Text category="c1" status="danger">
                {store.errorMessage}
              </Text>
            )}
            <View style={{ flexDirection: 'row', marginTop: 10 }}>
              <Text category="c1" appearance="hint">
                {t('profilenavigation.register.privacypolicy1')}
              </Text>
              <TouchableOpacity onPress={() => navigation.navigate('PrivacyPolicy')}>
                <Text category="c1" appearance="hint" status="primary">
                  {t('profilenavigation.register.privacypolicy2')}
                </Text>
              </TouchableOpacity>
              <Text category="c1" appearance="hint">
                {t('profilenavigation.register.privacypolicy3')}
              </Text>
            </View>
          </View>
          <Button style={styles.signUpButton} size="large" onPress={() => store.signUp(navigation)}>
            {t('profilenavigation.register.registeruppercase')}
          </Button>
          <Button appearance="ghost" status="basic" onPress={() => navigation.navigate('Login')}>
            {t('profilenavigation.register.registerquestion')}
          </Button>
          <Modal
            visible={store.loading}
            backdropStyle={{
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            }}>
            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
              <Spinner size="giant" />
            </View>
          </Modal>
        </KeyboardAvoidingView>
      );
    }
  )
);

const themedStyles = StyleService.create({
  container: {
    backgroundColor: 'background-basic-color-1',
  },
  headerContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 160,
    paddingVertical: 10,
    backgroundColor: 'color-primary-default',
  },
  signUpContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 32,
  },
  socialAuthContainer: {
    marginTop: 12,
  },
  socialAuthButtonsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
  socialAuthHintText: {
    alignSelf: 'center',
    marginBottom: 8,
  },
  formContainer: {
    marginTop: 20,
    paddingHorizontal: 16,
  },
  evaButton: {
    maxWidth: 72,
    paddingHorizontal: 0,
  },
  signInLabel: {
    flex: 1,
  },
  signInButton: {
    flexDirection: 'row-reverse',
    paddingHorizontal: 0,
  },
  signUpButton: {
    marginVertical: 2,
    marginHorizontal: 16,
  },
  socialAuthIcon: {
    tintColor: 'text-basic-color',
  },
  orContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: 16,
  },
  divider: {
    flex: 1,
  },
  orLabel: {
    marginHorizontal: 8,
  },
  emailSignLabel: {
    alignSelf: 'center',
    marginTop: 8,
  },
  formInput: {
    marginTop: 16,
  },
  termsCheckBox: {
    marginTop: 20,
  },
  termsCheckBoxText: {
    fontSize: 11,
    lineHeight: 14,
    color: 'text-hint-color',
  },
});
