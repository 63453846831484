import React from 'react';
import { ImageStyle } from 'react-native';
import { Icon, IconElement } from '@ui-kitten/components';

export const MessageCircleIcon = (style: ImageStyle): IconElement => (
  <Icon {...style} name="message-circle-outline" />
);

export const MoreHorizontalIcon = (style: ImageStyle): IconElement => (
  <Icon {...style} name="more-horizontal" />
);
