import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { HomeScreen } from '../homescreen/homepage';
import { MovieDetailScreen } from '../homescreen/moviedetail/moviedetailscreen';
import { RateMovie } from '../ratingscreen/ratemovie';
import { ExpandedMovieList } from '../homescreen/expandedmovielist';

const Stack = createStackNavigator();

export const HomePageNavigator = (): React.ReactElement => (
  <Stack.Navigator headerMode="none">
    <Stack.Screen name="Home" component={HomeScreen} />
    <Stack.Screen name="MovieDetail" component={MovieDetailScreen} />
    <Stack.Screen name="ExpanededList" component={ExpandedMovieList} />
    <Stack.Screen name="RateMovie" component={RateMovie} />
  </Stack.Navigator>
);
