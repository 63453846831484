import React from 'react';
import {
  ListRenderItemInfo,
  StyleSheet,
  View,
  ImageStyle,
  Dimensions,
  Platform,
} from 'react-native';
import StarRatingComponent from 'react-star-rating-component';
import {
  Button,
  Card,
  CardHeaderElement,
  List,
  ListProps,
  Text,
  withStyles,
  IconElement,
  Icon,
  OverflowMenu,
  Input,
  Spinner,
  Modal,
  Layout,
} from '@ui-kitten/components';
import { MoreHorizontalIcon } from './icons';
import { IRating } from './model';
import moment from 'moment';
import { Rating } from 'react-native-ratings';
import { Avatar, Overlay } from 'react-native-elements';
import { profilestore } from '../profiletab/profilestore';
import { moviestore } from '../homescreen/moviestore';
import { observer, useObservable, Observer } from 'mobx-react-lite';
import { UserProfileModal } from './userprofilemodal';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { withTranslation, WithTranslation } from 'react-i18next';
import { commentstore } from './commentstore';

const DISPLAY_WIDTH = Dimensions.get('window').width;
const DISPLAY_HEIGHT = Dimensions.get('window').height;
const ReportIcon = (style: ImageStyle): IconElement => (
  <Icon {...style} name="alert-circle-outline" />
);

const CheckMarkCircle = (style: ImageStyle): IconElement => (
  <Icon {...style} name="checkmark-circle-2-outline" />
);
const SpoilerIcon = (style: ImageStyle): IconElement => <Icon {...style} name="eye-off-outline" />;
export type CommentListProps = Omit<ListProps, 'renderItem'> & {
  navigation: any;
  t: any;
} & WithTranslation;
export const RenderCommentHeader = withTranslation()(
  observer(({ rating, theme, rateBarVisible, t, i18n, store }: any) => (
    <View style={styles.commentHeader}>
      <TouchableOpacity
        onPress={() => {
          moviestore.selectedRating = rating;
          moviestore.profileModal = true;
        }}>
        <Avatar
          rounded={true}
          title={`${rating.author.username[0]}`}
          source={{
            uri: rating.author.photoUrl,
          }}
        />
      </TouchableOpacity>
      <View style={styles.commentAuthorContainer}>
        <TouchableOpacity
          onPress={() => {
            moviestore.profileModal = true;
            moviestore.selectedRating = rating;
          }}>
          <Text category="s2">{rating.author.username}</Text>
          {rateBarVisible && (
            <View style={{ flexDirection: 'row', marginBottom: 2 }}>
              {Platform.OS === 'web' ? (
                <View>
                  <StarRatingComponent
                    name={'rating'}
                    value={rating.rating}
                    starCount={10}
                    editing={false}
                  />
                </View>
              ) : (
                <Rating
                  tintColor={profilestore.darkMode && theme['background-basic-color-1']}
                  readonly={true}
                  startingValue={rating.rating}
                  ratingCount={10}
                  imageSize={10}
                />
              )}
            </View>
          )}
          <Text appearance="hint" category="c1">
            {typeof rating.created_at === 'string'
              ? rating.created_at
              : i18n.language === 'en'
              ? moment(rating.created_at).format('DD.MM.YYYY hh:mm A')
              : moment(rating.created_at).format('DD.MM.YYYY HH:mm') + ' Uhr'}
          </Text>
        </TouchableOpacity>
      </View>
      {rateBarVisible && (
        <>
          <OverflowMenu
            data={[
              { icon: ReportIcon, title: t('moviedetail.report') },
              { icon: SpoilerIcon, title: t('moviedetail.spoiler') },
            ]}
            visible={store.reportMenu}
            selectedIndex={store.reportMenuIndex}
            onSelect={(index) => {
              store.reportMenu = false;
              store.reportMenuIndex = index;
              if (store.reportMenuIndex === 0) {
                store.report.type = 'other';
              } else {
                store.report.type = 'spoiler';
              }
              store.reportModal = true;
            }}
            onBackdropPress={() => (store.reportMenu = false)}>
            <Button
              disabled={!profilestore.authUser}
              onPress={() => {
                store.reportMenu = !store.reportMenu;
              }}
              style={styles.iconButton}
              appearance="ghost"
              status="basic"
              icon={MoreHorizontalIcon}
            />
          </OverflowMenu>
          {store.reportModal && (
            <Modal
              visible={store.reportModal}
              onBackdropPress={() => (store.reportModal = false)}
              backdropStyle={{
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
              }}>
              <Layout
                style={{
                  width: DISPLAY_WIDTH - 20,
                  height: DISPLAY_HEIGHT - 550,
                  borderRadius: 20,
                  padding: 20,
                }}>
                <View style={{ flex: 1, alignItems: 'center', justifyContent: 'space-evenly' }}>
                  <Text category="h5">
                    {t('moviedetail.reports.headline', { username: rating.author.username })}
                  </Text>
                  <Text style={{ marginVertical: 10 }} category="c2" status="danger">
                    {t('moviedetail.reports.warning')}
                  </Text>
                  <View style={{ marginBottom: 15, flexDirection: 'row' }}>
                    <Button
                      status={store.report.type === 'spoiler' ? 'primary' : 'basic'}
                      onPress={() => (store.report.type = 'spoiler')}>
                      {t('moviedetail.reports.spoiler')}
                    </Button>
                    <Button
                      status={store.report.type === 'other' ? 'primary' : 'basic'}
                      onPress={() => (store.report.type = 'other')}>
                      {t('moviedetail.reports.other')}
                    </Button>
                  </View>
                  <Input
                    multiline={true}
                    style={{ marginBottom: 10 }}
                    value={store.report.message}
                    onChangeText={(value) => {
                      store.report.message = value;
                    }}
                    textStyle={{ minHeight: 100 }}
                    placeholder={t('moviedetail.reports.infos')}
                  />
                  <View style={{ flexDirection: 'row' }}>
                    <Button
                      style={{ marginHorizontal: 5 }}
                      status="basic"
                      onPress={() => (store.reportModal = false)}>
                      {t('cancel')}
                    </Button>
                    {store.createReportLoading ? (
                      <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                        <Spinner />
                      </View>
                    ) : (
                      <Button
                        style={{ marginHorizontal: 5 }}
                        onPress={() => store.createReport(rating)}>
                        {t('moviedetail.reports.send')}
                      </Button>
                    )}
                  </View>
                </View>
              </Layout>
            </Modal>
          )}
          {store.successModal && (
            <Modal
              visible={store.successModal}
              onBackdropPress={() => (store.successModal = false)}
              backdropStyle={{
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
              }}>
              <Layout
                style={{
                  width: DISPLAY_WIDTH - 100,
                  height: DISPLAY_HEIGHT - 550,
                  borderRadius: 20,
                  padding: 20,
                }}>
                <View
                  style={{
                    paddingVertical: 20,
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  {CheckMarkCircle({ width: 150, height: 150, tintColor: '#FDCC0D' })}
                  <Text category="h6">
                    {t('moviedetail.reports.success', { username: rating.author.username })}
                  </Text>
                  <Text style={{ textAlign: 'center' }} category="s2">
                    {t('moviedetail.reports.successsubheadline')}
                  </Text>
                  <Button
                    style={{ marginHorizontal: 5 }}
                    onPress={() => (store.successModal = false)}>
                    {t('moviedetail.reports.continue')}
                  </Button>
                </View>
              </Layout>
            </Modal>
          )}
        </>
      )}
    </View>
  ))
);

const HeartIcon = (style: ImageStyle): IconElement => <Icon {...style} name="heart" />;
export const Comments = withTranslation()(
  observer(
    (props: CommentListProps): React.ReactElement => {
      const store = useObservable(new commentstore());
      const t = props.t;
      const renderItem = (info: ListRenderItemInfo<IRating>): React.ReactElement => (
        <Card
          style={styles.commentItem}
          header={() => (
            <RenderCommentHeader
              rating={info.item}
              theme={props.theme}
              rateBarVisible
              store={new commentstore()}
            />
          )}>
          <TouchableOpacity
            disabled={!info.item.spoiler}
            onPress={() => (info.item.spoiler = false)}>
            {info.item.spoiler ? (
              <Text appearance="hint">{t('moviedetail.spoilerwarning')}</Text>
            ) : (
              <Text> {info.item.text ? info.item.text : ''}</Text>
            )}
          </TouchableOpacity>
          <View style={styles.commentReactionsContainer}>
            {/* <Button
          style={styles.iconButton}
          appearance="ghost"
          status="basic"
          icon={MessageCircleIcon}>
          {`${info.item.comments.length}`}
        </Button> */}
            {store.likeLoading ? (
              <Spinner />
            ) : (
              <Button
                style={styles.iconButton}
                onPress={async () => {
                  await store.likeComment(info.item);
                }}
                disabled={!profilestore.authUser}
                appearance="ghost"
                status={
                  profilestore.authUser && info.item.likes.includes(profilestore.authUser?.uid)
                    ? 'danger'
                    : 'basic'
                }
                icon={HeartIcon}>
                {`${info.item.likes.length}`}
              </Button>
            )}
          </View>
        </Card>
      );
      return (
        <>
          <List
            {...props}
            ListEmptyComponent={
              <View
                style={{
                  flex: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginBottom: 20,
                }}>
                <Text>{t('moviedetail.emptyreviews')}</Text>
              </View>
            }
            renderItem={(info: ListRenderItemInfo<IRating>) => (
              <Observer>{() => renderItem(info)}</Observer>
            )}
          />
          {moviestore.profileModal && (
            <Modal
              visible={moviestore.profileModal}
              backdropStyle={{
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
              }}>
              <Layout
                style={{
                  width: DISPLAY_WIDTH - 50,
                  height: DISPLAY_HEIGHT - 200,
                  borderRadius: 20,
                  padding: 20,
                }}>
                <UserProfileModal navigation={props.navigation} />
              </Layout>
            </Modal>
          )}
        </>
      );
    }
  )
);
export const CommentList = withStyles(Comments);

const styles = StyleSheet.create({
  commentItem: {
    marginVertical: 8,
    marginHorizontal: 16,
  },
  commentHeader: {
    flexDirection: 'row',
    padding: 16,
  },
  commentAuthorContainer: {
    flex: 1,
    marginHorizontal: 16,
  },
  commentReactionsContainer: {
    flexDirection: 'row',
    marginTop: 8,
    marginHorizontal: -8,
    marginVertical: -8,
  },
  iconButton: {
    paddingHorizontal: 0,
  },
});
