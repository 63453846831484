import React, { useEffect } from 'react';
import { List, Text, Spinner, Layout } from '@ui-kitten/components';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { observer } from 'mobx-react';
import { MovieCard } from '../moviecard';
import { moviesliderstore } from './moviesliderstore';
import { useObservable } from 'mobx-react-lite';
import { moviestore } from '../moviestore';
import { withTranslation } from 'react-i18next';

export const MovieSlider = withTranslation()(
  observer(({ navigation, headline, requestUrl, containerStyle, t }: any) => {
    const movieStore = moviestore;
    const store = useObservable(new moviesliderstore());
    useEffect(() => {
      const initStore = async () => {
        if (requestUrl) {
          await store.init(requestUrl);
        }
        movieStore.refreshing = false;
      };
      initStore();
    }, [movieStore.refreshing]);
    if (movieStore.lastRatedTitles.length > 0 && !requestUrl) {
      store.titles = movieStore.lastRatedTitles;
      store.loading = movieStore.loadingLastRatedTitles;
    }
    return (
      <Layout style={containerStyle}>
        <View style={{ justifyContent: 'space-between', flexDirection: 'row' }}>
          <Text style={{ marginLeft: 8 }} category="s1">
            {headline}
          </Text>
          <TouchableOpacity
            activeOpacity={0.5}
            onPress={() =>
              navigation.navigate('ExpanededList', { titles: store.titles, title: headline })
            }>
            <Text status="primary" style={{ marginRight: 8 }} category="s2">
              {t('homepage.showmore')}
            </Text>
          </TouchableOpacity>
        </View>
        <List
          contentContainerStyle={styles.horizontalList}
          horizontal={true}
          showsHorizontalScrollIndicator={false}
          data={store.titles}
          renderItem={(info: any) => {
            return (
              <TouchableOpacity
                activeOpacity={0.9}
                onPress={() => {
                  console.log(info.item);
                  navigation.navigate('MovieDetail', {
                    id: info.item.id,
                    media_type: info.item.media_type,
                  });
                }}>
                <MovieCard style={styles.horizontalItem} poster={info.item.poster_path} />
              </TouchableOpacity>
            );
          }}
        />
      </Layout>
    );
  })
);
const styles = StyleSheet.create({
  horizontalList: {},
  horizontalItem: {
    marginLeft: 2,
    marginRight: 2,
  },
});
