export const myTheme = {
  'color-primary-100': '#FEF9CE',
  'color-primary-200': '#FEF19E',
  'color-primary-300': '#FEE66D',
  'color-primary-400': '#FDDC49',
  'color-primary-500': '#FDCC0D',
  'color-primary-600': '#D9AA09',
  'color-primary-700': '#B68A06',
  'color-primary-800': '#926C04',
  'color-primary-900': '#795702',
  'color-primary-transparent-100': 'rgba(253, 204, 13, 0.08)',
  'color-primary-transparent-200': 'rgba(253, 204, 13, 0.16)',
  'color-primary-transparent-300': 'rgba(253, 204, 13, 0.24)',
  'color-primary-transparent-400': 'rgba(253, 204, 13, 0.32)',
  'color-primary-transparent-500': 'rgba(253, 204, 13, 0.4)',
  'color-primary-transparent-600': 'rgba(253, 204, 13, 0.48)',
  'color-success-100': '#EBFDE2',
  'color-success-200': '#D4FBC7',
  'color-success-300': '#B4F3A7',
  'color-success-400': '#95E78D',
  'color-success-500': '#68D868',
  'color-success-600': '#4CB955',
  'color-success-700': '#349B46',
  'color-success-800': '#217D39',
  'color-success-900': '#136730',
  'color-success-transparent-100': 'rgba(104, 216, 104, 0.08)',
  'color-success-transparent-200': 'rgba(104, 216, 104, 0.16)',
  'color-success-transparent-300': 'rgba(104, 216, 104, 0.24)',
  'color-success-transparent-400': 'rgba(104, 216, 104, 0.32)',
  'color-success-transparent-500': 'rgba(104, 216, 104, 0.4)',
  'color-success-transparent-600': 'rgba(104, 216, 104, 0.48)',
  'color-info-100': '#DBEEFE',
  'color-info-200': '#B7DBFE',
  'color-info-300': '#93C4FE',
  'color-info-400': '#77B0FD',
  'color-info-500': '#4B8FFC',
  'color-info-600': '#366ED8',
  'color-info-700': '#2551B5',
  'color-info-800': '#173892',
  'color-info-900': '#0E2678',
  'color-info-transparent-100': 'rgba(75, 143, 252, 0.08)',
  'color-info-transparent-200': 'rgba(75, 143, 252, 0.16)',
  'color-info-transparent-300': 'rgba(75, 143, 252, 0.24)',
  'color-info-transparent-400': 'rgba(75, 143, 252, 0.32)',
  'color-info-transparent-500': 'rgba(75, 143, 252, 0.4)',
  'color-info-transparent-600': 'rgba(75, 143, 252, 0.48)',
  'color-warning-100': '#FEFBD3',
  'color-warning-200': '#FDF7A7',
  'color-warning-300': '#FBEF7A',
  'color-warning-400': '#F8E759',
  'color-warning-500': '#F4DC24',
  'color-warning-600': '#D1BA1A',
  'color-warning-700': '#AF9912',
  'color-warning-800': '#8D790B',
  'color-warning-900': '#756306',
  'color-warning-transparent-100': 'rgba(244, 220, 36, 0.08)',
  'color-warning-transparent-200': 'rgba(244, 220, 36, 0.16)',
  'color-warning-transparent-300': 'rgba(244, 220, 36, 0.24)',
  'color-warning-transparent-400': 'rgba(244, 220, 36, 0.32)',
  'color-warning-transparent-500': 'rgba(244, 220, 36, 0.4)',
  'color-warning-transparent-600': 'rgba(244, 220, 36, 0.48)',
  'color-danger-100': '#FFE9DC',
  'color-danger-200': '#FFCFBA',
  'color-danger-300': '#FFAE98',
  'color-danger-400': '#FF8F7E',
  'color-danger-500': '#FF5C54',
  'color-danger-600': '#DB3D43',
  'color-danger-700': '#B72A3B',
  'color-danger-800': '#931A33',
  'color-danger-900': '#7A102E',
  'color-danger-transparent-100': 'rgba(255, 92, 84, 0.08)',
  'color-danger-transparent-200': 'rgba(255, 92, 84, 0.16)',
  'color-danger-transparent-300': 'rgba(255, 92, 84, 0.24)',
  'color-danger-transparent-400': 'rgba(255, 92, 84, 0.32)',
  'color-danger-transparent-500': 'rgba(255, 92, 84, 0.4)',
  'color-danger-transparent-600': 'rgba(255, 92, 84, 0.48)',
};
