import React, { useEffect } from 'react';
import {
  TopNavigation,
  TopNavigationAction,
  Text,
  Layout,
  useStyleSheet,
  StyleService,
  Spinner,
} from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import { ArrowIosBackIcon } from '../icons/icons';
import { WebView } from 'react-native-webview';
import { ScrollView } from 'react-native-gesture-handler';
import { PolicyHTML } from './policytexts';
import { View, Platform } from 'react-native';
import { withTranslation } from 'react-i18next';
export const PrivacyPolicy = withTranslation()(
  observer(
    ({ navigation, route, t }: any): React.ReactElement => {
      const styles = useStyleSheet(themedStyles);
      const renderBackAction = (): React.ReactElement => (
        <TopNavigationAction icon={ArrowIosBackIcon} onPress={navigation.goBack} />
      );
      return (
        <>
          <TopNavigation
            title={t('profilenavigation.privacypolicy')}
            leftControl={renderBackAction()}
          />
          <Layout
            level="1"
            style={{
              flex: 1,
            }}>
            <View style={styles.container}>
              {Platform.OS !== 'web' ? (
                <WebView
                  source={{ html: PolicyHTML }}
                  style={{ padding: 10 }}
                  startInLoadingState
                  renderLoading={() => (
                    <Layout
                      style={{
                        flex: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: -1000,
                      }}>
                      <Spinner size="giant" />
                    </Layout>
                  )}
                />
              ) : (
                <div
                  style={{ overflowX: 'auto' }}
                  dangerouslySetInnerHTML={{ __html: PolicyHTML }}
                />
              )}
            </View>
          </Layout>
        </>
      );
    }
  )
);
const themedStyles = StyleService.create({
  container: {
    flex: 1,
    padding: 10,
    backgroundColor: 'background-basic-color-2',
  },
});
