import React from 'react';
import {
  TopNavigation,
  TopNavigationAction,
  Text,
  Layout,
  useStyleSheet,
  StyleService,
} from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import { ArrowIosBackIcon } from '../icons/icons';
import { ScrollView, TouchableOpacity } from 'react-native-gesture-handler';
import { Linking, View } from 'react-native';
import { withTranslation } from 'react-i18next';

export const LegalNotice = withTranslation()(
  observer(
    ({ navigation, route, t }: any): React.ReactElement => {
      const styles = useStyleSheet(themedStyles);
      const renderBackAction = (): React.ReactElement => (
        <TopNavigationAction icon={ArrowIosBackIcon} onPress={navigation.goBack} />
      );
      return (
        <>
          <TopNavigation title={t('profilenavigation.contact')} leftControl={renderBackAction()} />
          <Layout
            level="1"
            style={{
              flex: 1,
            }}>
            <ScrollView style={styles.container}>
              <Layout level="1" style={{ padding: 15 }}>
                <Text category="h5">{t('profilenavigation.contact')}</Text>
                <Text category="s2">{t('profilenavigation.contactNav.serviceprovider')}</Text>
                <Text category="p2">Luke Stahl</Text>
                <Text category="s2">{t('profilenavigation.contactNav.contactoptions')}</Text>
                <View style={{ flex: 1, flexDirection: 'row' }}>
                  <Text category="p2">{t('profilenavigation.contactNav.email')}: </Text>

                  <TouchableOpacity
                    onPress={() => Linking.openURL('mailto:ratingboxapp@gmail.com')}>
                    <Text status="info" category="p2">
                      ratingboxapp@gmail.com
                    </Text>
                  </TouchableOpacity>
                </View>
              </Layout>
            </ScrollView>
          </Layout>
        </>
      );
    }
  )
);
const themedStyles = StyleService.create({
  container: {
    flex: 1,
    padding: 10,
    backgroundColor: 'background-basic-color-2',
  },
});
