import { observable } from 'mobx';
import firebase from 'firebase';
class Store {
  @observable email = '';
  @observable loading = false;
  @observable message = '';

  async forgotPassword() {
    try {
      this.loading = true;
      await firebase.auth().sendPasswordResetEmail(this.email);
      console.log('forgot password mail send');
      this.message = 'E-Mail erfolgreich versendet.';
    } catch (error) {
      this.message = error.message;
      console.log(error);
    }
    this.loading = false;
  }
}

export const forgotpasswordstore = new Store();
