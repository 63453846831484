import React, { useEffect } from 'react';
import { Icon, Input, List, Spinner, Text, Layout } from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import { searchstore } from './searchstore';
import { View, Image, Dimensions, Keyboard } from 'react-native';
import { IResult } from './searchstore';
import { MovieCard } from '../homescreen/moviecard';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { withTranslation } from 'react-i18next';

const ITEM_WIDTH = Dimensions.get('window').width;
export const SearchMovie = withTranslation()(
  observer(({ navigation, t }: any) => {
    const store = searchstore;
    const searchRef = React.useRef<any>(null);
    React.useEffect(() => {
      const unsubscribe = navigation.addListener('focus', () => {
        searchRef.current.focus();
      });

      return unsubscribe;
    }, [navigation]);
    const SearchIcon = (props: any) => <Icon {...props} name="search" />;
    const CancelIcon = (props: any) => <Icon {...props} name="close-outline" />;

    return (
      <Layout style={{ flex: 1 }}>
        <View
          style={{ marginHorizontal: 10, flex: 1 }}
          onResponderGrant={() => {
            if (store.focusedInput) {
              Keyboard.dismiss();
            }
          }}
          onResponderMove={() => {
            if (store.focusedInput) {
              Keyboard.dismiss();
            }
          }}
          onResponderRelease={() => {
            if (store.focusedInput) {
              Keyboard.dismiss();
            }
          }}>
          <Input
            value={store.searchValue}
            placeholder={t('search.searchplaceholder')}
            style={{ marginBottom: 5 }}
            icon={store.focusedInput ? CancelIcon : SearchIcon}
            ref={searchRef}
            caption={
              store.searchResult.total_results &&
              `${store.searchResult.total_results} ${t('search.results')}`
            }
            onFocus={() => {
              searchRef.current.focus();
              store.focusedInput = true;
            }}
            onIconPress={() => {
              if (store.focusedInput) {
                store.searchResult.page = 1;
                store.searchValue = '';
                Keyboard.dismiss();
              }
            }}
            onBlur={() => (store.focusedInput = false)}
            blurOnSubmit
            onSubmitEditing={() => {
              if (store.searchValue === '') {
                store.searchResult = { page: 1, results: [] };
              }
            }}
            onChangeText={(text) => {
              store.searchResult.page = 1;
              store.searchValue = text;
              if (store.searchValue !== '') {
                store.search(text);
              } else {
                store.searchResult = { page: 1, results: [] };
              }
            }}
          />
          <List
            keyExtractor={(item) => item.id.toString()}
            numColumns={3}
            data={store.searchResult && store.searchResult.results}
            renderItem={({ item, index }: { item: IResult; index: number }) => (
              <TouchableOpacity
                key={index}
                activeOpacity={0.9}
                onPress={() =>
                  navigation.navigate('MovieDetail', {
                    id: item.id,
                    media_type: item.media_type,
                  })
                }>
                <MovieCard
                  style={{ margin: 5 }}
                  imageStyle={{
                    borderRadius: 5,
                    width: ITEM_WIDTH / 3 - 16,
                    height: ITEM_WIDTH / 2.2,
                  }}
                  poster={item.poster_path || ''}
                />
              </TouchableOpacity>
            )}
            onEndReached={() => {
              store.searchResult.page += 1;
              store.search(store.searchValue);
            }}
            ListFooterComponent={
              store.loading ? (
                <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                  <Spinner size="giant" />
                </View>
              ) : null
            }
            onEndReachedThreshold={0.5}
          />
        </View>
      </Layout>
    );
  })
);
