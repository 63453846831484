import { View, TouchableOpacity, Image, ImageBackground, Dimensions } from 'react-native';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { moviestore } from '../moviestore';
import ImageNotFound from '../moviedetail/assets/imagenotfound.png';
import { featuredstore } from './featuredstore';
import { BookmarkIcon, BookmarkOutlineIcon } from '../../icons/icons';
import ratingboxlogo from '../assets/airbnb-star-selected.png';
import { Text, Button, Spinner, useStyleSheet, StyleService } from '@ui-kitten/components';
import { profilestore } from '../../profiletab/profilestore';
import { withTranslation } from 'react-i18next';

const DISPLAY_WIDTH = Dimensions.get('window').width;
export const FeaturedBanner = withTranslation()(
  observer(({ navigation, t }: any) => {
    const store = featuredstore;
    const title = store.title;
    const styles = useStyleSheet(themedStyles);
    useEffect(() => {
      const initStore = async () => {
        await featuredstore.init();
      };
      initStore();
    }, [title]);
    return (
      <ImageBackground
        source={
          featuredstore.image
            ? {
                uri: `https://image.tmdb.org/t/p/original${featuredstore.image.file_path}`,
              }
            : ImageNotFound
        }
        style={{ width: DISPLAY_WIDTH, flex: 1 }}>
        <View
          style={{
            backgroundColor: 'rgba(0,0,0,0.5)',
          }}>
          <View style={{ marginVertical: 20 }}>
            <View style={{ flex: 1, flexDirection: 'row' }}>
              <View style={{ marginLeft: 25, marginRight: 25, flex: 1, justifyContent: 'center' }}>
                <TouchableOpacity
                  activeOpacity={0.9}
                  onPress={() => {
                    navigation.navigate('MovieDetail', {
                      id: title?.id,
                      media_type: store.mediaType,
                    });
                  }}>
                  <View>
                    <Image
                      style={{ borderRadius: 15, width: 150, height: 225, alignSelf: 'center' }}
                      source={
                        title?.poster_path
                          ? {
                              uri: `https://image.tmdb.org/t/p/w440_and_h660_face/${title.poster_path}`,
                            }
                          : ImageNotFound
                      }
                    />
                  </View>
                </TouchableOpacity>
              </View>
              {title && (
                <View style={{ flex: 2 }}>
                  <View style={{ flexDirection: 'column', marginBottom: 10 }}>
                    <View style={{ flex: 1, flexDirection: 'row', marginBottom: -8 }}>
                      <Image
                        style={{ width: 15, height: 25, marginHorizontal: 5 }}
                        resizeMode="contain"
                        source={ratingboxlogo}
                      />
                      <Text category="s1" style={{ color: 'white' }}>
                        RATING{' '}
                      </Text>
                      <Text category="s1" style={styles.primaryColor}>
                        BOX{' '}
                      </Text>
                      <Text category="s1" style={{ color: 'white' }}>
                        {t('homepage.choice')}
                      </Text>
                    </View>
                    <Text style={{ color: 'white', flex: 1 }} category="h3">
                      {title?.name || title?.title}
                    </Text>
                    <Text style={{ flex: 3, color: 'rgba(255,255,255,0.85)' }}>
                      {title?.overview && title?.overview.slice(0, 275)} ...
                    </Text>
                  </View>
                  <View style={{ flexDirection: 'row', justifyContent: 'flex-start' }}>
                    {!moviestore.bookMarkLoading ? (
                      moviestore.bookMarked ? (
                        <Button
                          appearance="ghost"
                          icon={BookmarkIcon}
                          onPress={async () =>
                            profilestore.authUser &&
                            (await moviestore.toggleWatchlist(title.id, store.mediaType, title))
                          }
                        />
                      ) : (
                        <Button
                          appearance="ghost"
                          icon={BookmarkOutlineIcon}
                          onPress={async () =>
                            profilestore.authUser &&
                            (await moviestore.toggleWatchlist(title.id, store.mediaType, title))
                          }
                        />
                      )
                    ) : (
                      <Spinner />
                    )}
                    <Button
                      onPress={() => {
                        navigation.navigate('MovieDetail', {
                          id: title.id,
                          media_type: store.mediaType,
                        });
                      }}
                      appearance="outline"
                      size="small">
                      {t('homepage.moreinfo')}
                    </Button>
                  </View>
                </View>
              )}
            </View>
          </View>
        </View>
      </ImageBackground>
    );
  })
);

const themedStyles = StyleService.create({
  primaryColor: {
    color: 'color-primary-default',
  },
});
