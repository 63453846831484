import { observable } from 'mobx';
import Axios from 'axios';
import * as Analytics from 'expo-firebase-analytics';
import i18next from 'i18next';

export interface ISearchResult {
  page: number;
  results: IResult[];
  total_results?: number;
  total_pages?: number;
}

export interface IResult {
  poster_path?: string;
  popularity: number;
  id: number;
  overview?: string;
  backdrop_path?: string;
  vote_average?: number;
  media_type: string;
  first_air_date?: string;
  origin_country?: string[];
  genre_ids?: number[];
  original_language?: string;
  vote_count?: number;
  name?: string;
  original_name?: string;
  adult?: boolean;
  release_date?: string;
  original_title?: string;
  title?: string;
  video?: boolean;
  profile_path?: string;
  known_for?: Knownfor[];
}

interface Knownfor {
  poster_path: string;
  adult?: boolean;
  overview: string;
  release_date?: string;
  original_title?: string;
  genre_ids: number[];
  id: number;
  media_type: string;
  original_language: string;
  title?: string;
  backdrop_path: string;
  popularity: number;
  vote_count: number;
  video?: boolean;
  vote_average: number;
  first_air_date?: string;
  origin_country?: string[];
  name?: string;
  original_name?: string;
}

class Store {
  @observable searchResult: ISearchResult = { page: 1, results: [] };

  @observable searchValue = '';
  @observable focusedInput = false;
  @observable loading = false;

  async search(query: string) {
    this.loading = true;
    try {
      console.log('Page', this.searchResult.page);
      const response = await Axios.get(
        `https://nextjs-movierater.now.sh/api/search?query=${query}&page=${this.searchResult.page}&language=${i18next.language}`
      );
      await Analytics.logEvent('Search', {
        search: query,
        screen: 'profile',
        purpose: 'Opens the internal settings',
      });
      if (response && response.data) {
        const searchResponse = response.data as ISearchResult;
        if (this.searchResult.page === 1) {
          this.searchResult = searchResponse;
        } else {
          this.searchResult = {
            page: searchResponse.page,
            results: [...this.searchResult.results, ...searchResponse.results],
            total_pages: searchResponse.total_pages,
            total_results: searchResponse.total_results,
          };
        }
      }
      this.loading = false;
    } catch (error) {
      console.log(error);
    }
  }
}

export const searchstore = new Store();
