import React from 'react';
import { View, Image, Platform } from 'react-native';
import {
  Button,
  Input,
  Layout,
  StyleService,
  Text,
  useStyleSheet,
  Icon,
  Divider,
  Modal,
  Spinner,
} from '@ui-kitten/components';
import {
  EyeIcon,
  EyeOffIcon,
  PersonIcon,
  GoogleIcon,
  FacebookIcon,
  TwitterIcon,
  MailIcon,
} from './extra/icons';
import { KeyboardAvoidingView } from './extra/3rd-party';
import { loginstore } from './loginstore';
import { observer } from 'mobx-react-lite';
import { withTranslation } from 'react-i18next';

export const LoginScreen = withTranslation()(
  observer(
    ({ navigation, t }: any): React.ReactElement => {
      const styles = useStyleSheet(themedStyles);
      const store = loginstore;

      return (
        <KeyboardAvoidingView style={styles.container}>
          <View style={styles.headerContainer}>
            <Image
              style={{ height: 100, width: 100 }}
              resizeMode="contain"
              source={require('./assets/logo-white-plain.png')}
            />
            <View style={{ flexDirection: 'row' }}>
              <Text category="h4">RATING </Text>
              <Text category="h4" style={{ color: 'white' }}>
                BOX
              </Text>
            </View>
            <Text category="c2">- {t('subheadline')} -</Text>
          </View>

          <View style={styles.socialAuthContainer}>
            <Text style={styles.socialAuthHintText} appearance="hint">
              {t('profilenavigation.login.sociallogin')}
            </Text>
            <View style={styles.socialAuthButtonsContainer}>
              <Button
                onPress={() => store.signInWithGoogleAsync()}
                appearance="ghost"
                size="giant"
                status="basic"
                icon={GoogleIcon}
                disabled={Platform.OS === 'web'}
              />
              <Button
                appearance="ghost"
                size="giant"
                status="basic"
                icon={FacebookIcon}
                onPress={() => loginstore.signInWithFacebook()}
                disabled={Platform.OS === 'web'}
              />
              {/* <Button appearance="ghost" size="giant" status="basic" icon={TwitterIcon} /> */}
            </View>
          </View>
          <View style={styles.orContainer}>
            <Divider style={styles.divider} />
            <Text style={styles.orLabel} appearance="hint" category="h5">
              {t('profilenavigation.login.loginor')}
            </Text>
            <Divider style={styles.divider} />
          </View>
          <Layout style={styles.formContainer} level="1">
            <Input
              placeholder={t('profilenavigation.login.email')}
              autoCapitalize="none"
              icon={MailIcon}
              autoCompleteType="email"
              value={store.email}
              onChangeText={(text) => (store.email = text)}
            />
            <Input
              style={styles.passwordInput}
              placeholder={t('profilenavigation.login.password')}
              autoCompleteType="password"
              icon={store.passwordVisible ? EyeIcon : EyeOffIcon}
              value={store.password}
              secureTextEntry={!store.passwordVisible}
              onChangeText={(text) => (store.password = text)}
              onSubmitEditing={() => store.signIn()}
              onIconPress={() => (store.passwordVisible = !store.passwordVisible)}
            />
            <View style={styles.forgotPasswordContainer}>
              <Button
                style={styles.forgotPasswordButton}
                appearance="ghost"
                status="basic"
                onPress={() => navigation.navigate('ForgotPassword')}>
                {t('profilenavigation.login.forgotpassword')}
              </Button>
            </View>
            <Text category="c1" status="danger">
              {store.errorMessage}
            </Text>
          </Layout>
          <Button style={[styles.signInButton]} onPress={() => store.signIn()} size="large">
            {t('profilenavigation.login.loginuppercase')}
          </Button>
          <Button
            style={styles.signUpButton}
            appearance="ghost"
            status="basic"
            onPress={() => navigation.navigate('SignUp')}>
            {t('profilenavigation.login.loginquestion')}
          </Button>

          <Modal
            visible={store.loading}
            backdropStyle={{
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            }}>
            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
              <Spinner size="giant" />
            </View>
          </Modal>
        </KeyboardAvoidingView>
      );
    }
  )
);

const themedStyles = StyleService.create({
  container: {
    backgroundColor: 'background-basic-color-1',
  },
  headerContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 160,
    paddingVertical: 10,
    backgroundColor: 'color-primary-default',
  },
  formContainer: {
    paddingTop: 32,
    paddingHorizontal: 16,
  },
  socialAuthButtonsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginLeft: 40,
    marginRight: 40,
  },
  signInLabel: {
    marginTop: 16,
  },
  signInButton: {
    marginHorizontal: 16,
    marginTop: 20,
  },
  socialAuthHintText: {
    alignSelf: 'center',
    marginBottom: 16,
  },
  signInGoogle: {
    marginHorizontal: 16,
    marginBottom: 10,
  },
  orContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: 16,
    marginTop: 20,
  },
  divider: {
    flex: 1,
  },
  orLabel: {
    marginHorizontal: 8,
  },
  socialAuthContainer: {
    marginTop: 24,
  },
  signUpButton: {
    marginVertical: 5,
    marginHorizontal: 16,
  },
  forgotPasswordContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  passwordInput: {
    marginTop: 16,
  },
  forgotPasswordButton: {
    paddingHorizontal: 0,
  },
});
