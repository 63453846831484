import React, { useEffect } from 'react';
import { ScrollView, View, ImageStyle, Dimensions, Platform, Linking } from 'react-native';
import {
  Button,
  Layout,
  StyleService,
  Text,
  useStyleSheet,
  TopNavigation,
  TopNavigationAction,
  IconElement,
  Icon,
  Modal,
  Spinner,
  Card,
  Input,
  ListItem,
  Divider,
} from '@ui-kitten/components';
import { ProfileSetting } from './extra/profile-setting.component';
import { observer } from 'mobx-react-lite';
import firebase from 'firebase';
import { profilestore } from './profilestore';
import moment from 'moment';
import { ThemeContext } from '../../theme-context';
import { Avatar } from 'react-native-elements';
import { AsyncStorage } from 'react-native';
import { EyeIcon, EyeOffIcon } from '../loginscreen/extra/icons';
import { CheckMarkIcon, CloseIcon } from '../icons/icons';
import { BarPasswordStrengthDisplay } from 'react-native-password-strength-meter';
import * as StoreReview from 'expo-store-review';
import * as Analytics from 'expo-firebase-analytics';
import { withTranslation } from 'react-i18next';
const DISPLAY_WIDTH = Dimensions.get('window').width;

export const DarkModeIcon = (style: ImageStyle): IconElement => (
  <Icon {...style} name="moon-outline" />
);
export const LightModeIcon = (style: ImageStyle): IconElement => (
  <Icon {...style} name="sun-outline" />
);

const renderForwardIcon = (): React.ReactElement => (
  <Icon style={{ width: 30, height: 30 }} tintColor="#c2c9d6" name="arrow-ios-forward-outline" />
);

export const ProfileScreen = withTranslation()(
  observer(
    ({ navigation, route, t, i18n }: any): React.ReactElement => {
      const styles = useStyleSheet(themedStyles);
      const themeContext = React.useContext(ThemeContext);
      const store = profilestore;

      const themeSwitchAction = (): React.ReactElement => (
        <TopNavigationAction
          icon={store.darkMode ? DarkModeIcon : LightModeIcon}
          onPress={async () => {
            await Analytics.logEvent('SwitchThemeMode', {
              username: profilestore.user?.username,
              screen: 'profile',
              purpose: 'Opens the internal settings',
            });
            themeContext.toggleTheme();
            store.darkMode = !store.darkMode;
            await AsyncStorage.setItem('@darkmode', store.darkMode.toString());
          }}
        />
      );
      useEffect(() => {
        const initStore = async () => {
          if (route && route.params && route.params.user) {
            await store.initUser(route.params.user);
          }
        };
        initStore();
      }, [route.params.user]);

      return (
        <>
          <TopNavigation
            title={t('profilenavigation.profilenav')}
            titleStyle={{ marginLeft: 10 }}
            rightControls={[themeSwitchAction()]}
          />
          <Layout
            level="1"
            style={{
              flex: 1,
            }}>
            <ScrollView style={styles.container} contentContainerStyle={styles.contentContainer}>
              <Layout style={styles.photoSection} level="1">
                <Avatar
                  rounded
                  containerStyle={styles.avatarcontainer}
                  title={`${store.user?.username[0]}`}
                  source={{
                    uri: store.user?.profile_picture,
                  }}
                  size={85}
                />
                <View style={styles.nameSection}>
                  <ProfileSetting
                    onChange={(text) => {
                      if (store.user && typeof text === 'string') {
                        store.user.username = text;
                      }
                    }}
                    isInput
                    style={styles.setting}
                    value={store.user?.username || ''}
                  />
                </View>
              </Layout>
              <ListItem
                title={t('profilenavigation.email')}
                style={[styles.setting, styles.emailSetting]}
                accessory={() => <Text>{store.user?.mail || ''}</Text>}
              />
              <ListItem
                title={t('profilenavigation.password')}
                style={styles.setting}
                accessory={() => (
                  <Button
                    onPress={() => {
                      store.passwordResetModal = true;
                      store.oldPassword = '';
                      store.passwordVisible = false;
                      store.newPassword = '';
                      store.newPasswordVerification = '';
                      store.errorMessage = '';
                    }}
                    size="small">
                    {t('profilenavigation.changepassword')}
                  </Button>
                )}
              />
              <ListItem
                title={t('profilenavigation.createdat')}
                style={styles.setting}
                accessory={() => (
                  <Text>
                    {i18n.language === 'en'
                      ? moment(store.user?.created_at).format('DD.MM.YYYY hh:mm A')
                      : moment(store.user?.created_at).format('DD.MM.YYYY HH:mm') + ' Uhr'}
                  </Text>
                )}
              />
              <Divider style={{ marginBottom: 50 }} />
              <ListItem
                title={t('profilenavigation.reviewapp')}
                titleStyle={{ color: '#8F9BB3' }}
                style={styles.setting}
                onPress={async () => {
                  await Analytics.logEvent('RateApp', {
                    name: profilestore.user?.username,
                    screen: 'profile',
                    purpose: 'Opens the internal settings',
                  });
                  Platform.OS === 'android' || Platform.OS === 'web'
                    ? Linking.openURL(
                        'https://play.google.com/store/apps/details?id=com.infinitystudiomobile.ratingbox'
                      )
                    : StoreReview.requestReview();
                }}
                accessory={renderForwardIcon}
              />
              <ListItem
                title={t('profilenavigation.privacypolicy')}
                titleStyle={{ color: '#8F9BB3' }}
                style={styles.setting}
                onPress={() => navigation.navigate('PrivacyPolicy')}
                accessory={renderForwardIcon}
              />
              <ListItem
                title={t('profilenavigation.supportapp') + ' 💛'}
                titleStyle={{ color: '#8F9BB3' }}
                style={styles.setting}
                onPress={async () => {
                  await Analytics.logEvent('Unterstützung', {
                    name: profilestore.user?.username,
                    screen: 'profile',
                    purpose: 'Opens the internal settings',
                  });
                  Linking.openURL('https://paypal.me/stahlluke');
                }}
                accessory={renderForwardIcon}
              />
              <ListItem
                title={t('profilenavigation.language')}
                titleStyle={{ color: '#8F9BB3' }}
                style={styles.setting}
                onPress={() => navigation.navigate('Localization')}
                accessory={renderForwardIcon}
              />
              <ListItem
                title={t('profilenavigation.contact')}
                titleStyle={{ color: '#8F9BB3' }}
                style={styles.setting}
                onPress={() => navigation.navigate('LegalNotice')}
                accessory={renderForwardIcon}
              />
              <ListItem
                title={t('profilenavigation.logout')}
                titleStyle={{ color: '#8F9BB3' }}
                style={styles.setting}
                onPress={async () => {
                  await Analytics.logEvent('Logout', {
                    name: profilestore.user?.username,
                    screen: 'profile',
                    purpose: 'Opens the internal settings',
                  });
                  firebase.auth().signOut();
                }}
              />
            </ScrollView>
            <Modal
              visible={store.updateInProgress}
              backdropStyle={{
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
              }}>
              <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                <Spinner size="giant" />
                <Text category="h2">{t('profilenavigation.usernamesavemessage')}</Text>
              </View>
            </Modal>
            <Modal
              visible={store.passwordResetModal}
              style={{ width: DISPLAY_WIDTH - 50 }}
              backdropStyle={{
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
              }}>
              <Card
                header={() => (
                  <View style={{ alignItems: 'center' }}>
                    <Text category="h6" style={{ margin: 10 }}>
                      {t('profilenavigation.changepassworduppercase')}
                    </Text>
                  </View>
                )}>
                <Input
                  style={styles.formInput}
                  placeholder={t('profilenavigation.oldpassword')}
                  autoCompleteType="password"
                  secureTextEntry={!store.passwordVisible}
                  onIconPress={() => (store.passwordVisible = !store.passwordVisible)}
                  icon={store.passwordVisible ? EyeIcon : EyeOffIcon}
                  value={store.oldPassword}
                  onChangeText={(text) => (store.oldPassword = text)}
                />
                <Input
                  style={styles.formInput}
                  placeholder={t('profilenavigation.newpassword')}
                  autoCompleteType="password"
                  secureTextEntry={!store.passwordVisible}
                  onIconPress={() => (store.passwordVisible = !store.passwordVisible)}
                  icon={store.passwordVisible ? EyeIcon : EyeOffIcon}
                  value={store.newPassword}
                  onChangeText={(text) => (store.newPassword = text)}
                />
                {store.newPassword !== '' && (
                  <BarPasswordStrengthDisplay
                    minLength={0}
                    password={store.newPassword}
                    width={DISPLAY_WIDTH - 130}
                    barContainerStyle={{ marginBottom: 5 }}
                  />
                )}
                <Input
                  style={styles.formInput}
                  placeholder={t('profilenavigation.passwordconfirmation')}
                  autoCompleteType="password"
                  secureTextEntry={!store.passwordVisible}
                  value={store.newPasswordVerification}
                  icon={
                    store.newPasswordVerification !== '' &&
                    store.newPasswordVerification === store.newPassword
                      ? CheckMarkIcon
                      : CloseIcon
                  }
                  status={
                    store.newPassword === ''
                      ? 'basic'
                      : store.newPassword === store.newPasswordVerification
                      ? 'success'
                      : 'danger'
                  }
                  onChangeText={(text) => (store.newPasswordVerification = text)}
                />
                {store.errorMessage !== '' && <Text status="danger">{store.errorMessage}</Text>}
                <View
                  style={{ justifyContent: 'space-between', flexDirection: 'row', marginTop: 6 }}>
                  <Button
                    style={styles.footerControl}
                    onPress={() => (store.passwordResetModal = false)}
                    status="basic">
                    {t('cancel')}
                  </Button>
                  <Button style={styles.footerControl} onPress={() => store.resetPassword()}>
                    {t('save')}
                  </Button>
                </View>
              </Card>
              <Modal
                visible={store.passwordResetLoading}
                backdropStyle={{
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                }}>
                <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                  <Spinner size="giant" />
                  <Text category="h2" style={{ color: 'white' }}>
                    {t('profilenavigation.passwordloading')}
                  </Text>
                </View>
              </Modal>
            </Modal>
          </Layout>
        </>
      );
    }
  )
);

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'background-basic-color-2',
  },
  avatarcontainer: {
    borderWidth: 2,
    borderColor: 'color-primary-default',
  },
  card: {
    flex: 1,
    margin: 2,
  },
  footerControl: {
    marginHorizontal: 2,
  },
  contentContainer: {
    paddingBottom: 24,
  },
  photoSection: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 16,
    paddingVertical: 10,
  },
  photo: {
    aspectRatio: 1.0,
    height: 76,
  },
  photoButton: {
    aspectRatio: 1.0,
    height: 32,
    borderRadius: 16,
  },
  nameSection: {
    flex: 1,
    marginHorizontal: 8,
  },
  formInput: {
    marginTop: 10,
  },
  saveProfile: {
    marginHorizontal: 100,
    marginBottom: 10,
  },
  doneButton: {
    marginHorizontal: 24,
    marginTop: 24,
  },
  setting: {
    padding: 16,
    paddingVertical: 15,
    marginVertical: 2,
  },
  emailSetting: {
    marginTop: 24,
  },
});
