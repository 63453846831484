import React from 'react';
import { StyleSheet } from 'react-native';
import { Divider, Layout, LayoutProps, Text, Input, StyleService } from '@ui-kitten/components';
import { profilestore } from '../profilestore';
import { withTranslation } from 'react-i18next';

export interface ProfileSettingProps extends LayoutProps {
  hint?: string;
  value?: string;
  onChange?: (text: string) => void;
  isInput?: boolean;
  render?: JSX.Element;
  t: any;
}

export const ProfileSetting = withTranslation()(
  (props: ProfileSettingProps): React.ReactElement => {
    const { style, hint, value, isInput, t, ...layoutProps } = props;

    const renderHintElement = (): React.ReactElement => (
      <Text appearance="hint" category="s1">
        {hint}
      </Text>
    );

    return (
      <React.Fragment>
        <Layout level="1" {...layoutProps} style={[styles.container, style]}>
          {hint && renderHintElement()}
          {!props.render ? (
            <>
              {!isInput ? (
                <Text>{value}</Text>
              ) : (
                <Input
                  label={t('profilenavigation.username')}
                  labelStyle={styles.label}
                  style={styles.input}
                  value={value}
                  onChangeText={props.onChange}
                  onBlur={() => profilestore.updateUser()}
                />
              )}
            </>
          ) : (
            props.render
          )}
        </Layout>
        {!isInput && <Divider />}
      </React.Fragment>
    );
  }
);

const styles = StyleService.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  input: { backgroundColor: 'transparent', flex: 1 },
  label: {
    marginLeft: 5,
  },
});
