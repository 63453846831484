export const firebaseConfig = {
  apiKey: 'AIzaSyBjJSAIy1WRZSCDUoWdZwqeLF5iuanI5bU',
  authDomain: 'expomovierater.firebaseapp.com',
  databaseURL: 'https://expomovierater.firebaseio.com',
  projectId: 'expomovierater',
  storageBucket: 'expomovierater.appspot.com',
  messagingSenderId: '676199702200',
  appId: '1:676199702200:web:dcc6dc5d58343fad2a1e4d',
  measurementId: 'G-WPV3B18XX8',
};
