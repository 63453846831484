import React, { ReactElement } from 'react';
import { StyleSheet, Image, View, ImageStyle } from 'react-native';
import { CardElement, Text, IconElement, Icon } from '@ui-kitten/components';
import ImageNotFound from './moviedetail/assets/imagenotfound.png';

interface IProps {
  style?: any;
  poster: string;
  imageStyle?: any;
}
export const AltertIcon = () => <Icon name="alert-triangle-outline" />;

export const MovieCard = (props: IProps): CardElement => {
  const { style, poster, imageStyle, ...cardProps } = props;

  const imageUrl = (imageStyle && imageStyle.width && 'w185') || 'w440_and_h660_face';
  return (
    <View {...cardProps} style={[styles.container, style]}>
      <Image
        style={imageStyle ? imageStyle : { borderRadius: 15, width: 150, height: 225 }}
        source={
          poster
            ? {
                uri: `https://image.tmdb.org/t/p/${imageUrl}/${poster}`,
              }
            : ImageNotFound
        }
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {},
});
