import React from 'react';
import * as eva from '@eva-design/eva';
import { ApplicationProvider, IconRegistry } from '@ui-kitten/components';
import { EvaIconsPack } from '@ui-kitten/eva-icons';
import { ThemeContext } from './theme-context';
import { AppNavigator } from './src/navigation/navigation';
import { firebaseConfig } from './config';
import * as firebase from 'firebase';
import '@firebase/firestore';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { myTheme } from './custom-theme';
import './src/localization/i18n';

firebase.initializeApp(firebaseConfig);

export default () => {
  const [theme, setTheme] = React.useState('light');
  console.disableYellowBox = true;

  const toggleTheme = () => {
    const nextTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(nextTheme);
  };

  return (
    <>
      <IconRegistry icons={EvaIconsPack} />
      <ThemeContext.Provider value={{ theme, toggleTheme }}>
        <ApplicationProvider {...eva} theme={{ ...eva[theme], ...myTheme }}>
          <SafeAreaProvider>
            <AppNavigator />
          </SafeAreaProvider>
        </ApplicationProvider>
      </ThemeContext.Provider>
    </>
  );
};
