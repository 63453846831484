import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { MyRatingsList } from '../myratings/myratingslist';
import { moviestore } from '../homescreen/moviestore';
import {
  Button,
  Layout,
  Text,
  StyleService,
  useStyleSheet,
  Divider,
  Spinner,
} from '@ui-kitten/components';
import { View } from 'react-native';
import { Avatar } from 'react-native-elements';
import { myratingsstore } from '../myratings/myratingsstore';
import firebase from 'firebase';
import 'firebase/functions';
import moment from 'moment';

export const UserProfileModal = observer(({ navigation }: { navigation: any }) => {
  const user = moviestore.selectedRating?.author;
  const [creationDate, setCreationDate] = useState(0);
  useEffect(() => {
    const initStore = async () => {
      const likeReviewFunction = firebase.functions().httpsCallable('getCreationDate');
      const response = await likeReviewFunction({
        userId: user?.userId,
      });
      if (response.data) {
        setCreationDate(response.data);
      }
    };
    initStore();
  }, []);
  const styles = useStyleSheet(themedStyles);
  return (
    <Layout level="0" style={{ flex: 1 }}>
      {user && (
        <>
          <View style={{ flexDirection: 'row' }}>
            <Avatar
              rounded
              title={`${user.username}`}
              source={{
                uri: user?.photoUrl,
              }}
              containerStyle={styles.avatarcontainer}
              size={85}
            />
            <View style={{ margin: 10, flex: 2 }}>
              <Text category="h6">{user.username}</Text>
              <Text appearance="hint" category="c1">
                Begetreten am:{' '}
                {creationDate ? moment(creationDate).format('DD.MM.YYYY') : <Spinner />}
              </Text>
              <Text category="s2">
                Anzahl an Bewertungen:{' '}
                {myratingsstore.loaded ? (
                  <Text category="h6">{myratingsstore.myratings.length.toString()}</Text>
                ) : (
                  <Spinner size="small" />
                )}
              </Text>
            </View>
          </View>
          <Divider style={{ marginVertical: 10 }} />
          <Text appearance="h4" style={{ marginLeft: 10 }}>
            Letzte Bewertungen:
          </Text>
          <MyRatingsList userId={user.userId} navigation={navigation} />
        </>
      )}
      <Button
        appearance="primary"
        style={{ width: '80%', alignSelf: 'center' }}
        onPress={() => (moviestore.profileModal = false)}>
        Zurück
      </Button>
    </Layout>
  );
});

const themedStyles = StyleService.create({
  avatarcontainer: {
    borderWidth: 2,
    borderColor: 'color-primary-default',
  },
});
