import React from 'react';
import {
  TopNavigation,
  TopNavigationAction,
  Layout,
  useStyleSheet,
  StyleService,
  ListItem,
  Icon,
  Button,
} from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import { ArrowIosBackIcon } from '../icons/icons';
import { languages } from '../localization/languages';
import { ScrollView, AsyncStorage } from 'react-native';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
export const Localization = withTranslation()(
  observer(
    ({ navigation, t, i18n }: any): React.ReactElement => {
      const styles = useStyleSheet(themedStyles);
      const renderBackAction = (): React.ReactElement => (
        <TopNavigationAction icon={ArrowIosBackIcon} onPress={navigation.goBack} />
      );
      const renderItemIcon = () => (
        <Icon style={{ height: 30, width: 30 }} tintColor={'green'} name="checkmark-outline" />
      );
      return (
        <>
          <TopNavigation title={t('changeLocalization')} leftControl={renderBackAction()} />
          <ScrollView style={styles.container} contentContainerStyle={styles.contentContainer}>
            <Layout
              level="1"
              style={{
                flex: 1,
              }}>
              {languages.map((language) => (
                <ListItem
                  title={language.display}
                  titleStyle={{ color: '#8F9BB3' }}
                  style={styles.setting}
                  onPress={async () => {
                    i18n.changeLanguage(language.name);
                    await AsyncStorage.setItem('@language', i18next.language);
                    navigation.navigate('Profile');
                  }}
                  accessory={
                    i18next.language.includes(language.name) ? renderItemIcon : () => <></>
                  }
                />
              ))}
            </Layout>
          </ScrollView>
        </>
      );
    }
  )
);
const themedStyles = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'background-basic-color-2',
  },
  setting: {
    padding: 16,
    paddingVertical: 15,
    marginVertical: 2,
  },
  contentContainer: {
    paddingBottom: 24,
  },
});
