import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { MovieDetailScreen } from '../homescreen/moviedetail/moviedetailscreen';
import { Watchlist } from '../watchlist/watchlist';

const Stack = createStackNavigator();

export const WatchlistNavigation = (): React.ReactElement => (
  <Stack.Navigator headerMode="none">
    <Stack.Screen name="Watchlist" component={Watchlist} />
    <Stack.Screen name="MovieDetail" component={MovieDetailScreen} />
  </Stack.Navigator>
);
