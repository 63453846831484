import { observer, useObservable } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import {
  Spinner,
  StyleService,
  useStyleSheet,
  Text,
  List,
  Layout,
  withStyles,
} from '@ui-kitten/components';
import { View, Image, Platform } from 'react-native';
import { myratingsstore, IMyRating } from './myratingsstore';
import StarRatingComponent from 'react-star-rating-component';
import { Rating } from 'react-native-ratings';
import moment from 'moment';
import { ArrowIosForwardIcon } from '../icons/icons';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { profilestore } from '../profiletab/profilestore';
import { withTranslation } from 'react-i18next';

export const RatingsList = withTranslation()(
  observer(
    ({ navigation, theme, userId, clickable = true, t, i18n }: any): React.ReactElement => {
      const styles = useStyleSheet(themedStyle);
      const store = useObservable(myratingsstore);

      useEffect(() => {
        if (userId) {
          const initStore = async () => {
            await store.initMyRatings(userId);
          };
          initStore();
        }
      }, []);

      const renderItem = ({ item }: { item: IMyRating }) => (
        <TouchableOpacity
          onPress={() =>
            navigation.navigate('MovieDetail', {
              id: item.movieId,
              media_type: item.media_type,
            })
          }
          disabled={!clickable}>
          <Layout level="1" style={{ padding: 10, margin: 5, borderRadius: 10 }}>
            <View style={{ flexDirection: 'row' }}>
              <Image
                style={{ borderRadius: 5, width: 50, height: 75 }}
                source={{
                  uri: `https://image.tmdb.org/t/p/w440_and_h660_face/${item.movie.poster_path}`,
                }}
              />
              <View
                style={{
                  flex: 1,
                  flexDirection: 'column',
                  marginLeft: 10,
                  justifyContent: 'space-evenly',
                }}>
                <View>
                  <Text category="s1">{item.movie?.name || item.movie?.title}</Text>
                  {Platform.OS === 'web' ? (
                    <View style={{ alignItems: 'start' }}>
                      <StarRatingComponent
                        name={'Rating1'}
                        value={item.rating}
                        starCount={10}
                        editing={false} /* is component available for editing, default `true` */
                      />
                    </View>
                  ) : (
                    <Rating
                      style={{ alignItems: 'start' } as any}
                      readonly={true}
                      startingValue={item.rating}
                      ratingCount={10}
                      imageSize={15}
                      tintColor={profilestore.darkMode && theme['background-basic-color-1']}
                    />
                  )}
                </View>
                <Text appearance="hint" category="c1">
                  {t('saved.myratings.from')}:{' '}
                  {typeof item.created_at === 'string'
                    ? item.created_at
                    : i18n.language === 'en'
                    ? moment(item.created_at).format('DD.MM.YYYY hh:mm A')
                    : moment(item.created_at).format('DD.MM.YYYY HH:mm') + ' Uhr'}
                </Text>
              </View>
              <View
                style={{
                  position: 'absolute',
                  zIndex: 10,
                  right: -15,
                  alignSelf: 'center',
                }}>
                <ArrowIosForwardIcon width={50} height={25} tintColor={'#8F9BB3'} />
              </View>
            </View>
          </Layout>
        </TouchableOpacity>
      );

      return (
        <Layout level="0" style={{ flex: 1 }}>
          <View style={styles.container}>
            {store.loaded ? (
              <List
                data={store.myratings}
                renderItem={renderItem}
                ListEmptyComponent={
                  <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                    <Text>
                      {userId ? t('saved.myratings.emptyforuser') : t('saved.myratings.empty')}
                    </Text>
                  </View>
                }
              />
            ) : (
              <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                <Spinner size="giant" />
              </View>
            )}
          </View>
        </Layout>
      );
    }
  )
);
export const MyRatingsList = withStyles(RatingsList);
const themedStyle = StyleService.create({
  container: {
    marginHorizontal: 10,
    marginTop: 10,
    flex: 1,
  },
});
