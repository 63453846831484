import firebase from 'firebase';
import { profilestore } from '../profiletab/profilestore';
import { IUser } from '../profiletab/model';

class Service {
  getUserDocument(): firebase.firestore.DocumentReference<IUser> {
    return firebase
      .firestore()
      .collection('users')
      .doc(profilestore.authUser?.uid) as firebase.firestore.DocumentReference<IUser>;
  }
}

export const fsservice = new Service();
