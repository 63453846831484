import React from 'react';
import { List, Layout, TopNavigation, TopNavigationAction } from '@ui-kitten/components';
import { TouchableOpacity, Dimensions, View } from 'react-native';
import { observer } from 'mobx-react';
import { MovieCard } from './moviecard';
import { ArrowIosBackIcon } from '../icons/icons';
const ITEM_WIDTH = Dimensions.get('window').width;

export const ExpandedMovieList = observer(({ route, navigation }: any) => {
  return (
    <>
      <TopNavigation
        leftControl={<TopNavigationAction icon={ArrowIosBackIcon} onPress={navigation.goBack} />}
        title={route.params.title}
        titleStyle={{ marginLeft: 10 }}
      />
      <Layout level="2" style={{ flex: 1 }}>
        <View style={{ marginHorizontal: 10 }}>
          <List
            data={route.params.titles}
            numColumns={3}
            horizontal={false}
            renderItem={(info: any) => {
              return (
                <TouchableOpacity
                  key={info.index}
                  activeOpacity={0.9}
                  onPress={() =>
                    navigation.navigate('MovieDetail', {
                      id: info.item.id,
                      media_type: info.item.media_type,
                    })
                  }>
                  <MovieCard
                    style={{ margin: 5 }}
                    imageStyle={{
                      borderRadius: 5,
                      width: ITEM_WIDTH / 3 - 16,
                      height: ITEM_WIDTH / 2.2,
                    }}
                    poster={info.item.poster_path}
                  />
                </TouchableOpacity>
              );
            }}
          />
        </View>
      </Layout>
    </>
  );
});
