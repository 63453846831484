import React, { useEffect } from 'react';
import {
  TopNavigation,
  TopNavigationAction,
  Text,
  Icon,
  Input,
  Layout,
  Spinner,
  Modal,
  withStyles,
  CheckBox,
} from '@ui-kitten/components';
import { ArrowIosBackIcon, BookmarkIcon, BookmarkOutlineIcon } from '../icons/icons';
import { observer } from 'mobx-react-lite';
import { renderCommentHeader, RenderCommentHeader } from '../commentsection/commentlist';
import { profilestore } from '../profiletab/profilestore';
import { View, Vibration, Platform, Dimensions } from 'react-native';
import { AirbnbRating } from 'react-native-ratings';
import { ratingstore } from './ratingstore';
import { withTranslation } from 'react-i18next';
import StarRatingComponent from 'react-star-rating-component';
import i18next from 'i18next';

const PaperIcon = (props: any) => <Icon {...props} name="paper-plane-outline" />;

export const RatingScreen = withTranslation()(
  observer(
    ({ navigation, route, theme, t }: any): React.ReactElement => {
      const store = ratingstore;
      const renderBackAction = (): React.ReactElement => (
        <TopNavigationAction icon={ArrowIosBackIcon} onPress={navigation.goBack} />
      );

      const renderBookmarkAction = (): React.ReactElement => (
        <TopNavigationAction
          icon={PaperIcon}
          onPress={async () => {
            store.errors = [];
            if (
              (store.rating?.text === '' || store.rating?.text.length > 100) &&
              store.rating?.rating !== 0
            ) {
              store.saving = true;
              await store.rateMovie();
              store.saving = false;
              navigation.navigate('MovieDetail', {
                id: route.params.movieId,
                media_type: route.params.mediaType,
              });
            } else if (store.rating?.text && store.rating.text.length < 100) {
              store.errors.push('text');
            } else {
              store.errors.push('rating');
            }
          }}
        />
      );

      useEffect(() => {
        store.rating = {
          author: {
            username: profilestore.user?.username,
            photoUrl: profilestore.user?.profile_picture,
            userId: profilestore.authUser?.uid,
          },
          language: i18next.language,
          comments: [],
          likes: [],
          rating: 0,
          created_at: t('moviedetail.publicreview'),
          text: '',
          media_type: route.params.mediaType,
          movieId: route.params.movieId,
          spoiler: false,
        };
      }, [route.params]);
      return (
        <Layout style={{ flex: 1 }}>
          <TopNavigation
            alignment="center"
            title={route.params.title || ''}
            leftControl={renderBackAction()}
            rightControls={[renderBookmarkAction()]}
          />
          {store.rating && (
            <>
              <View style={{ marginLeft: 5, marginRight: 5 }}>
                <RenderCommentHeader rating={store.rating} theme={theme} />
              </View>
              <View
                style={{
                  flex: 1,
                  alignItems: 'center',
                }}>
                {Platform.OS === 'web' ? (
                  <View>
                    <StarRatingComponent
                      name={'Rating3'}
                      value={store.rating.rating || 0}
                      onStarClick={(nextValue: number) => {
                        if (store.rating) {
                          store.rating.rating = nextValue;
                        }
                      }}
                      starCount={10}
                    />
                  </View>
                ) : (
                  <AirbnbRating
                    onFinishRating={(value) => {
                      if (store.rating) {
                        Vibration.vibrate(500);
                        store.rating.rating = value;
                      }
                    }}
                    count={10}
                    defaultRating={0}
                    showRating={false}
                    starStyle={{ width: 25, height: 25 }}
                  />
                )}
                {store.errors.includes('rating') && (
                  <Text status="danger">{t('moviedetail.ratingerror')}</Text>
                )}

                <Input
                  style={{
                    marginLeft: 15,
                    marginRight: 15,
                    marginTop: 10,
                    maxHeight: 300,
                    width: '80%',
                  }}
                  multiline={true}
                  value={store.rating.text}
                  onChangeText={(value) => {
                    if (store.rating) {
                      store.rating.text = value;
                    }
                  }}
                  status={store.errors.includes('text') ? 'danger' : undefined}
                  textStyle={{ minHeight: 100 }}
                  placeholder={`${t('moviedetail.sharereview')} (optional)`}
                  caption={
                    store.rating.text !== '' && store.rating.text.length < 100
                      ? t('moviedetail.charactersleft', { value: 100 - store.rating.text.length })
                      : undefined
                  }
                />
                <CheckBox
                  text={t('moviedetail.containsspoiler')}
                  style={{ alignSelf: 'flex-start', marginLeft: 20 }}
                  checked={store.rating.spoiler}
                  onChange={(checked) => store.rating && (store.rating.spoiler = checked)}
                />
              </View>

              <Modal
                visible={store.saving}
                backdropStyle={{
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                }}>
                <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                  <Spinner size="giant" />
                </View>
              </Modal>
            </>
          )}
        </Layout>
      );
    }
  )
);

export const RateMovie = withStyles(RatingScreen);
