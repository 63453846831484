import { observable } from 'mobx';
import Axios from 'axios';
import { ITitle } from 'src/model/titles';
import { fsservice } from '../firebaseservice/firebaseservice';
import { watchliststore } from '../watchlist/watchliststore';
import firebase from 'firebase';
import { IRating } from 'src/commentsection/model';
import { profilestore } from '../profiletab/profilestore';
import * as Analytics from 'expo-firebase-analytics';
import i18next from 'i18next';

class Store {
  @observable title: ITitle | undefined;
  @observable ratings: IRating[] = [];
  @observable titleLoaded = false;
  @observable bookMarked = false;
  @observable bookMarkLoading = false;

  @observable userRating: IRating | undefined;
  @observable refreshing = false;
  @observable ratingMenu = false;
  @observable ratingMenuIndex = 1;

  @observable profileModal = false;
  @observable selectedRating: IRating | undefined;

  @observable lastRatedTitles: ITitle[] = [];
  @observable loadingLastRatedTitles = false;

  @observable featured = false;

  async loadTitle(id: string, mediaType: string): Promise<void> {
    this.titleLoaded = false;
    try {
      const response = await Axios.get(
        `https://nextjs-movierater.now.sh/api/titles?titleId=${id}&media_type=${mediaType}&language=${i18next.language}`
      );
      console.log(response.data);
      if (response && response.data) {
        this.title = response.data;
      }
      if (profilestore.authUser) {
        this.bookMarked = await watchliststore.checkInWatchlist(id);
      }
      await this.loadRatings(id);
      await Analytics.logEvent('LoadMovieTitle', {
        name: this.title?.title,
        usernam: profilestore.user?.username,
        purpose: 'Opens the internal settings',
      });
      this.titleLoaded = true;
    } catch (error) {
      console.log(error);
    }
  }

  async loadRatings(id: string) {
    let ratingsTmp: IRating[] = [];
    const response = await firebase
      .firestore()
      .collection('ratings')
      .where('movieId', '==', id)
      .get();
    if (!response.empty) {
      response.docs.forEach((doc) => ratingsTmp.push({ ...(doc.data() as IRating), id: doc.id }));
      let values = ratingsTmp.map((value) => value.rating);
      let sum = values.reduce((previous, current) => (current += previous));
      let avg = sum / ratingsTmp.length;
      if (this.title) {
        this.title.movierater_vote_average = avg.toFixed(1);
        this.title.movierater_vote_count = ratingsTmp.length;
      }
    }
    this.ratings = ratingsTmp.filter((rating) => i18next.language.includes(rating.language));
    this.userRating = this.getUserRating(ratingsTmp);
  }

  async toggleWatchlist(id: string, media_type: string, newtitle?: ITitle): Promise<void> {
    const wasBookmarked = this.bookMarked;
    this.bookMarked = !this.bookMarked;
    this.bookMarkLoading = true;
    const title = this.title ? this.title : newtitle;
    await fsservice.getUserDocument().update({
      watchlist: wasBookmarked
        ? firebase.firestore.FieldValue.arrayRemove({
            movieId: id,
            mediaType: media_type,
            poster_path: title?.poster_path,
          })
        : firebase.firestore.FieldValue.arrayUnion({
            movieId: id,
            mediaType: media_type,
            poster_path: title?.poster_path,
          }),
    });
    this.bookMarkLoading = false;
  }

  private getUserRating(ratings: IRating[]): IRating | undefined {
    return ratings.find((rating) => rating.author.userId === profilestore.authUser?.uid);
  }

  public async checkFeatured(id: string): Promise<void> {
    const response = await firebase.firestore().collection('featured').limit(1).get();
    if (!response.empty) {
      const featuredMovie = response.docs[0].data();
      this.featured = featuredMovie.movieId === id;
    }
  }
  public async onFeaturePress(id: string, mediaType: string): Promise<void> {
    this.featured = !this.featured;
    this.bookMarkLoading = true;
    if (this.featured) {
      const docToDelete = await firebase.firestore().collection('featured').limit(1).get();
      if (docToDelete.docs.length > 0) {
        await firebase.firestore().collection('featured').doc(docToDelete.docs[0].id).delete();
      }
      await firebase.firestore().collection('featured').add({
        movieId: id,
        mediaType: mediaType,
      });
    } else {
      const docToDelete = await firebase.firestore().collection('featured').limit(1).get();
      await firebase.firestore().collection('featured').doc(docToDelete.docs[0].id).delete();
    }

    this.bookMarkLoading = false;
  }
}

export const moviestore = new Store();
