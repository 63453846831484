import { en } from './en';

export const de: typeof en = {
  translation: {
    homepage: {
      headlinepopular: 'DERZEIT BELIEBT',
      headlineshow: 'BELIEBTE SERIEN',
      headlinemovies: 'BELIEBTE FILME',
      lastreviewed: 'ZULETZT BEWERTET',
      showmore: 'mehr anzeigen',
      choice: 'EMPFIELT',
      moreinfo: 'Weitere Infos',
    },
    search: {
      searchplaceholder: 'Suche nach Film oder Serie',
      results: 'Ergebnisse',
    },
    changeLocalization: 'Sprache ändern',
    profilenavigation: {
      profilenav: 'Profil',
      username: 'BENUTZERNAME',
      usernamesavemessage: 'Speichern...',
      email: 'E-Mail',
      password: 'Passwort',
      changepassword: 'Passwort ändern',
      createdat: 'Erstellt am',
      reviewapp: 'App bewerten',
      privacypolicy: 'Datenschutz',
      supportapp: 'Unterstütze die App',
      language: 'Sprache',
      contact: 'Kontakt',
      contactNav: {
        contactoptions: 'Kontaktmöglichkeiten',
        email: 'E-Mail-Adresse',
        serviceprovider: 'Diensteanbieter',
      },
      logout: 'Abmelden',
      changepassworduppercase: 'PASSWORT ÄNDERN',
      oldpassword: 'Altes Password',
      newpassword: 'Neues Password',
      passwordconfirmation: 'Passwort bestätigen',
      passwordloading: 'Passwort ändern...',
      login: {
        sociallogin: 'Mit Sozialem Netzwerk anmelden',
        email: 'E-Mail',
        forgotpassword: 'Passwort vergessen?',
        loginor: 'ODER',
        loginquestion: 'Noch keinen Account? Jetzt registrieren.',
        loginuppercase: 'ANMELDEN',
        password: 'Passwort',
      },
      register: {
        email: 'E-Mail',
        password: 'Passwort',
        passwordconfirmation: 'Passwort bestätigen',
        privacypolicy1: 'Ich habe die ',
        privacypolicy2: 'Nutzungsbedingungen',
        privacypolicy3: ' gelesen und akzeptiert',
        registerquestion: 'Bereits einen Account? Jetzt anmelden',
        registeruppercase: 'REGISTRIEREN',
        socialregister: 'Mit Sozialem Netzwerk registrieren',
        username: 'Username',
        registeror: 'ODER',
      },
      forgotpassword: {
        email: 'E-Mail',
        login: 'Anmelden?',
        reset: 'PASSWORT ZURÜCKSETZEN',
        send: 'SENDEN',
      },
    },
    saved: {
      watchlist: {
        headline: 'WATCHLIST',
        empty: 'Ooops... Deine Watchlist scheint leer zu sein',
        loginerror: 'Erstelle einen Account um deine Watchlist anzusehen',
      },
      myratings: {
        headline: 'MEINE BEWERTUNGEN',
        emptyforuser: 'Dieser Nutzer hat noch keine Bewertungen',
        empty:
          'Du hast bisher noch keine Bewertungen abgegeben. Teile jetzt deine Meinung mit anderen Nutzern!',
        from: 'Vom',
      },
    },
    moviedetail: {
      year: 'Jahr',
      type: 'Art',
      runtime: 'Länge',
      streamingportals: 'Streaming Portale',
      description: 'Beschreibung',
      media: 'Medien',
      reviews: 'Rezensionen',
      yourreview: 'Deine Bewertung:',
      writereview: 'Bewertung schreiben',
      sharereview: 'Teile deine Meinung mit anderen Nutzern',
      emptyreviews: 'Ooops! .. Noch keine Bewertungen vorhanden',
      publicreview: 'wird öffentlich gepostet',
      containsspoiler: 'Enthält Spoiler',
      delete: 'Löschen',
      movie: 'Film',
      report: 'Melden',
      spoiler: 'Spoiler',
      tv: 'Serie',
      season: 'Staffeln',
      noaccount: 'Erstelle einen Account um eine Bewertung zu schreiben',
      spoilerwarning: 'Diese Bewertung enthält einen Spoiler. Diese Bewertung anzeigen?',
      charactersleft: 'Noch mindestens {{value}} Zeichen',
      ratingerror: 'Geben sie eine Bewertung ab.',
      reports: {
        headline: 'Möchtest du die Bewertung von {{username}} melden?',
        warning: 'Achtung: Falsche Meldungen können zur Account Sperre führen.',
        spoiler: 'Enthält Spoiler',
        other: 'z.B. Werbung, Spoiler',
        infos: 'Weitere Infos',
        send: 'Absenden',
        success: 'Du hast die Bewertung von {{username}} erfolgreich gemeldet!',
        successsubheadline: 'Wir werden uns schnellstmöglich darum kümmern',
        continue: 'Weiter',
      },
    },
    subheadline: 'Bewerte Filme und Serien',
    cancel: 'Abbrechen',
    save: 'Speichern',
  },
};
