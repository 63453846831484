import { observable } from 'mobx';
import * as Google from 'expo-google-app-auth';
import * as Facebook from 'expo-facebook';
import firebase from 'firebase';
import { IUser } from 'src/profiletab/model';
import * as Analytics from 'expo-firebase-analytics';
class Store {
  @observable loggedIn = false;
  @observable loading = false;

  @observable email = '';
  @observable password = '';
  @observable passwordVisible = false;

  @observable errorMessage = '';

  isUserEqual = (googleUser: any, firebaseUser: any) => {
    if (firebaseUser) {
      var providerData = firebaseUser.providerData;
      for (var i = 0; i < providerData.length; i++) {
        if (
          providerData[i].providerId === firebase.auth.GoogleAuthProvider.PROVIDER_ID &&
          providerData[i].uid === googleUser.getBasicProfile().getId()
        ) {
          return true;
        }
      }
    }
    return false;
  };
  onSignIn = (googleUser: any) => {
    console.log('Google Auth Response', googleUser);
    var unsubscribe = firebase.auth().onAuthStateChanged((firebaseUser) => {
      unsubscribe();
      if (!this.isUserEqual(googleUser, firebaseUser)) {
        var credential = firebase.auth.GoogleAuthProvider.credential(
          googleUser.idToken,
          googleUser.accessToken
        );
        firebase
          .auth()
          .signInWithCredential(credential)
          .then(async (result) => {
            this.loading = false;
            await this.createOrUpdateUser(result);
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        console.log('User already signed-in Firebase.');
      }
    });
  };

  async createOrUpdateUser(
    userCredential: firebase.auth.UserCredential,
    singUpAdditionalData?: { username: string }
  ) {
    if (
      userCredential &&
      userCredential.user &&
      userCredential.user.email &&
      (userCredential.additionalUserInfo?.isNewUser || singUpAdditionalData)
    ) {
      let newUser: IUser = {
        mail: userCredential.user.email,
        watchlist: [],
        created_at: Date.now(),
        last_logged_in: Date.now(),
      };
      if (
        userCredential.additionalUserInfo &&
        userCredential.additionalUserInfo.isNewUser &&
        userCredential.additionalUserInfo.profile
      ) {
        const profile = userCredential.additionalUserInfo.profile as any;
        if (
          userCredential.credential?.providerId === firebase.auth.GoogleAuthProvider.PROVIDER_ID
        ) {
          newUser.profile_picture = profile.picture;
          newUser.username = profile.given_name + ' ' + profile.family_name;
        } else if (
          userCredential.credential?.providerId === firebase.auth.FacebookAuthProvider.PROVIDER_ID
        ) {
          newUser.profile_picture = profile.picture.data.url;
          newUser.username = profile.first_name + ' ' + profile.last_name;
        }
      } else if (singUpAdditionalData) {
        newUser.profile_picture = '';
        newUser.username = singUpAdditionalData?.username;
      }
      console.log('create user', newUser);
      await firebase.firestore().collection('users').doc(userCredential.user.uid).set(newUser);
    } else {
      if (userCredential && userCredential.user) {
        await firebase
          .firestore()
          .collection('users')
          .doc(userCredential.user.uid)
          .update({ last_logged_in: Date.now() });
      }
    }
  }

  signInWithGoogleAsync = async () => {
    try {
      this.loading = true;
      const result = await Google.logInAsync({
        androidClientId: '676199702200-7a48286brabsldmv1s3tb92ehjsct07v.apps.googleusercontent.com',
        androidStandaloneAppClientId:
          '676199702200-7a48286brabsldmv1s3tb92ehjsct07v.apps.googleusercontent.com',
        behavior: 'web',
        iosClientId: '676199702200-9evlsk8d29jvprqqttqajooi20rsj4n8.apps.googleusercontent.com',
        scopes: ['profile', 'email'],
      });

      if (result.type === 'success') {
        this.onSignIn(result);
        return result.accessToken;
      } else {
        this.loading = false;
        return { cancelled: true };
      }
    } catch (e) {
      this.loading = false;
      return { error: true };
    }
  };

  async signInWithFacebook() {
    this.loading = true;
    const appId = '2734424716882885';
    const permissions = ['public_profile', 'email']; // Permissions required, consult Facebook docs
    await Facebook.initializeAsync(appId);
    const { type, token } = await Facebook.logInWithReadPermissionsAsync({
      permissions,
    });

    switch (type) {
      case 'success': {
        await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL); // Set persistent auth state
        const credential = firebase.auth.FacebookAuthProvider.credential(token);
        const facebookProfileData = await firebase
          .auth()
          .signInAndRetrieveDataWithCredential(credential); // Sign in with Facebook credential
        console.log(facebookProfileData);
        // Do something with Facebook profile data
        // OR you have subscribed to auth state change, authStateChange handler will process the profile data
        await this.createOrUpdateUser(facebookProfileData);
        break;
      }
      case 'cancel': {
        break;
      }
      default:
    }
    this.loading = false;
  }
  signIn = async () => {
    // await firebase
    //   .auth()
    //   .createUserWithEmailAndPassword(this.email, this.password)
    //   .then(async (result: any) => await this.createOrUpdateUser(result))
    //   .catch(function (error) {
    //     console.log(error);
    //   });
    // const actionCodeSettings = {
    //   // URL you want to redirect back to. The domain (www.example.com) for this
    //   // URL must be whitelisted in the Firebase Console.
    //   url: 'https://expomovierater.firebaseapp.com/finishSignUp?cartId=1234',
    //   // This must be true.
    //   handleCodeInApp: true,
    //   iOS: {
    //     bundleId: 'com.example.ios',
    //   },
    //   android: {
    //     packageName: 'com.example.android',
    //     installApp: true,
    //     minimumVersion: '12',
    //   },
    //   dynamicLinkDomain: 'expomovierater.firebaseapp.com',
    // };
    // await firebase.auth().sendSignInLinkToEmail(this.email, actionCodeSettings);
    // console.log('email send');
    await Analytics.logEvent('Login', {
      name: 'login',
      screen: 'profile',
      purpose: 'Opens the internal settings',
    });
    try {
      this.loading = true;
      await firebase.auth().signInWithEmailAndPassword(this.email, this.password);
    } catch (error) {
      this.errorMessage = error.message;
    }
    this.loading = false;
  };
}

export const loginstore = new Store();
