import { observable } from 'mobx';
import firebase from 'firebase';
import { IUser } from './model';
class Store {
  @observable user: IUser | undefined;
  @observable authUser: firebase.User | undefined;

  @observable updateInProgress = false;

  @observable passwordResetModal = false;
  @observable passwordVisible = false;
  @observable oldPassword = '';
  @observable newPassword = '';
  @observable newPasswordVerification = '';
  @observable passwordResetLoading = false;
  @observable errorMessage = '';

  @observable darkMode = false;

  async initUser(authUser: firebase.User): Promise<void> {
    let response;
    response = await firebase.firestore().collection('users').doc(authUser.uid).get();
    if (response) {
      this.user = response.data() as IUser;
    }
  }

  getUser = (): firebase.User | null => {
    let firebaseUser = null;
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        firebaseUser = user;
      }
    });
    console.log(firebaseUser);
    return firebaseUser;
  };

  async updateUser() {
    try {
      this.updateInProgress = true;
      if (this.user) {
        await firebase.firestore().collection('users').doc(this.authUser?.uid).update(this.user);
      }
    } catch (error) {
      console.log(error);
    }
    this.updateInProgress = false;
  }

  async resetPassword() {
    try {
      this.passwordResetLoading = true;
      console.log('reset');
      this.errorMessage = '';
      if (this.newPassword !== this.newPasswordVerification) {
        throw new Error('Passwort stimmt nicht überein');
      }
      const credential = firebase.auth.EmailAuthProvider.credential(
        this.authUser?.email,
        this.oldPassword
      );
      await firebase.auth().currentUser?.reauthenticateWithCredential(credential);
      await firebase.auth().currentUser?.updatePassword(this.newPassword);
      console.log('Reset Password');
      this.passwordResetModal = false;
    } catch (error) {
      this.errorMessage = error.message;
      console.log(error);
    }
    this.passwordResetLoading = false;
  }
}

export const profilestore = new Store();
