import React from 'react';
import { View, Dimensions } from 'react-native';
import {
  Button,
  Input,
  StyleService,
  Text,
  useStyleSheet,
  Spinner,
  Modal,
} from '@ui-kitten/components';
import { KeyboardAvoidingView } from './extra/3rd-party';
import { observer } from 'mobx-react-lite';
import { MailIcon } from './extra/icons';
import { forgotpasswordstore } from './forgotpasswordstore';
import { withTranslation } from 'react-i18next';

export const ForgotPassword = withTranslation()(
  observer(
    ({ navigation, t }: any): React.ReactElement => {
      const styles = useStyleSheet(themedStyles);
      const store = forgotpasswordstore;

      return (
        <KeyboardAvoidingView style={styles.container}>
          <View style={styles.headerContainer}>
            <Text category="h4" status="control">
              {t('profilenavigation.forgotpassword.reset')}
            </Text>
          </View>
          <View style={[styles.container, styles.formContainer]}>
            <Input
              style={styles.formInput}
              placeholder={t('profilenavigation.forgotpassword.email')}
              autoCapitalize="none"
              icon={MailIcon}
              autoCompleteType="email"
              value={store.email}
              onChangeText={(text) => (store.email = text)}
            />
            <Text status="success">{store.message}</Text>
          </View>
          <Button style={styles.signUpButton} size="large" onPress={() => store.forgotPassword()}>
            {t('profilenavigation.forgotpassword.send')}
          </Button>
          <Button appearance="ghost" status="basic" onPress={() => navigation.navigate('Login')}>
            {t('profilenavigation.forgotpassword.login')}
          </Button>
          <Modal
            visible={store.loading}
            backdropStyle={{
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            }}>
            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
              <Spinner size="giant" />
            </View>
          </Modal>
        </KeyboardAvoidingView>
      );
    }
  )
);

const themedStyles = StyleService.create({
  container: {
    backgroundColor: 'background-basic-color-1',
  },
  headerContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 150,
    backgroundColor: 'color-primary-default',
  },
  signUpContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 32,
  },
  socialAuthContainer: {
    marginTop: 24,
  },
  socialAuthButtonsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
  socialAuthHintText: {
    alignSelf: 'center',
    marginBottom: 16,
  },
  formContainer: {
    marginTop: 20,
    paddingHorizontal: 16,
  },
  evaButton: {
    maxWidth: 72,
    paddingHorizontal: 0,
  },
  signInLabel: {
    flex: 1,
  },
  signInButton: {
    flexDirection: 'row-reverse',
    paddingHorizontal: 0,
  },
  signUpButton: {
    marginVertical: 5,
    marginHorizontal: 16,
  },
  socialAuthIcon: {
    tintColor: 'text-basic-color',
  },
  orContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: 16,
    marginTop: 10,
  },
  divider: {
    flex: 1,
  },
  orLabel: {
    marginHorizontal: 8,
  },
  emailSignLabel: {
    alignSelf: 'center',
    marginTop: 8,
  },
  formInput: {
    marginTop: 16,
  },
  termsCheckBox: {
    marginTop: 20,
  },
  termsCheckBoxText: {
    fontSize: 11,
    lineHeight: 14,
    color: 'text-hint-color',
  },
});
