import { observable } from 'mobx';
import firebase from 'firebase';
import { loginstore } from './loginstore';
import * as Analytics from 'expo-firebase-analytics';

class Store {
  @observable loading = false;

  @observable username = '';
  @observable email = '';
  @observable password = '';
  @observable passwordVerfification = '';
  @observable passwordVisible = false;

  @observable errorMessage = '';
  async signUp(navigation: any) {
    try {
      this.loading = true;
      if (this.password !== this.passwordVerfification) {
        throw new Error('Passwort stimmt nicht überein');
      }
      const credentials = await firebase
        .auth()
        .createUserWithEmailAndPassword(this.email, this.password);
      await loginstore.createOrUpdateUser(credentials, {
        username: this.username,
      });
      await Analytics.logEvent('Signup', {
        name: 'signup',
        username: this.username,
        purpose: 'Opens the internal settings',
      });
      navigation.navigation('ProfileCheck');
    } catch (error) {
      this.errorMessage = error.message;
    }
    this.loading = false;
  }
}

export const signupstore = new Store();
