import { observable } from 'mobx';
import Axios from 'axios';
import { ITitle } from 'src/model/titles';

export class moviesliderstore {
  @observable titles: ITitle[] = [];
  @observable loading = false;

  async init(url: string): Promise<void> {
    this.loading = true;
    try {
      const response = await Axios.get(url);
      if (response && response.data) {
        this.titles = response.data;
      }
      this.loading = false;
    } catch (error) {
      console.log(error);
    }
  }
}
