import React from 'react';
import { StyleSheet, View, ViewProps } from 'react-native';
import { ListProps, Text } from '@ui-kitten/components';
import { TouchableHighlight } from 'react-native-gesture-handler';

export type DetailsListProps = Omit<ListProps, 'renderItem'>;

export interface DetailsListItem {
  title: string | JSX.Element;
  description: string | JSX.Element;
  onPress?: () => void;
  touchable?: boolean;
}

export const DetailsList = (props: DetailsListProps): React.ReactElement<ViewProps> => {
  const { style, data, ...viewProps } = props;
  const renderItem = (item: DetailsListItem, index: number): React.ReactElement => {
    return (
      item && (
        <TouchableHighlight
          activeOpacity={0.5}
          underlayColor={'background-basic-color-2'}
          disabled={!item.onPress}
          onPress={() => item.onPress && item.onPress()}>
          <View key={index} style={styles.item}>
            {typeof item.title === 'string' ? (
              <Text appearance="hint" category="s2">
                {item.title}
              </Text>
            ) : (
              <>{item.title}</>
            )}
            {typeof item.description === 'string' ? (
              <Text category="s1">{item.description}</Text>
            ) : (
              item.description
            )}
          </View>
        </TouchableHighlight>
      )
    );
  };
  return (
    <View {...viewProps} style={[styles.container, style]}>
      {data.map(renderItem)}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
  item: {
    alignItems: 'center',
    marginHorizontal: 16,
  },
});
