import { observable, action } from 'mobx';
import { IRating } from './model';
import { profilestore } from '../profiletab/profilestore';
import { moviestore } from '../homescreen/moviestore';
import firebase from 'firebase';
import 'firebase/functions';

export interface IReport {
  type: 'spoiler' | 'other';
  message: string;
}

class Store {
  @observable reportMenu = false;
  @observable reportMenuIndex = 2;
  @observable reportModal = false;
  @observable report = { message: '' } as IReport;
  @observable successModal = false;
  @observable createReportLoading = false;
  @observable likeLoading = false;

  async createReport(rating: IRating) {
    this.createReportLoading = true;
    const reportFirebaseFunction = firebase.functions().httpsCallable('reportReview');
    try {
      const response = await reportFirebaseFunction({
        ratingId: rating.id,
        type: this.report.type,
        message: this.report.message,
      });
      if (response.data === 'Success' && profilestore.authUser) {
        this.successModal = true;
        this.reportModal = false;
      }
    } catch (error) {
      console.log(error);
    }

    this.createReportLoading = false;
  }

  async likeComment(rating: IRating) {
    this.likeLoading = true;
    const liked = profilestore.authUser && rating.likes.includes(profilestore.authUser?.uid);
    const likeReviewFunction = firebase.functions().httpsCallable('likeReview');
    const response = await likeReviewFunction({
      ratingId: rating.id,
      type: liked ? 'remove' : 'add',
    });
    if (response.data === 'Success' && profilestore.authUser) {
      liked
        ? (rating.likes = rating.likes.filter((uid) => uid !== profilestore.authUser?.uid))
        : rating.likes.push(profilestore.authUser?.uid);
      moviestore.ratings = moviestore.ratings.concat([]);
    }
    this.likeLoading = false;
  }
}

export const commentstore = Store;
