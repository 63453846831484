import Carousel from 'react-native-snap-carousel';
import React, { useEffect } from 'react';
import { Text, View, Image, Linking, Dimensions, Platform } from 'react-native';
import { observer, useObservable } from 'mobx-react-lite';
import { carouselstore, IResult, IBackdrop, ICarouselItem } from './carouselstore';
import { Spinner, Modal, Card } from '@ui-kitten/components';
import { TouchableOpacity, TouchableHighlight } from 'react-native-gesture-handler';
import { Icon, Overlay } from 'react-native-elements';
import Slider from 'react-slick';
if (Platform.OS === 'web') {
  // REMOVE FOR BUILD
  require('slick-carousel/slick/slick.css');
  require('slick-carousel/slick/slick-theme.css');
}
const DISPLAY_WIDTH = Dimensions.get('window').width;
const DISPLAY_HEIGHT = Dimensions.get('window').height;
export const CustomImageCarousel = observer(
  ({ id, movieType }: { id: number; movieType: string }) => {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    const store = useObservable(carouselstore);

    useEffect(() => {
      const initStore = async () => {
        store.init(id, movieType);
      };
      initStore();
    }, []);

    const _renderItem = ({ item, index }: { item: ICarouselItem; index: number }) => {
      if (item.type === 'image') {
        return (
          <TouchableOpacity
            activeOpacity={0.9}
            disabled={Platform.OS === 'web'}
            onPress={() => {
              store.imageModal = true;
              store.index = index;
            }}>
            <Image
              style={{
                width: Platform.OS === 'web' ? 600 : 180,
                height: Platform.OS === 'web' ? 300 : 120,
                borderRadius: 8,
                marginHorizontal: 8,
              }}
              source={{ uri: item.uri }}
            />
          </TouchableOpacity>
        );
      } else {
        return (
          <TouchableOpacity
            activeOpacity={0.9}
            onPress={() => item.videoUrl && Linking.openURL(item.videoUrl)}>
            <View
              style={{
                marginHorizontal: 8,
              }}>
              <View
                style={{
                  position: 'absolute',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%',
                  width: '100%',
                  zIndex: 10,
                  borderRadius: 8,
                  backgroundColor: 'rgba(0,0,0,0.3)',
                }}>
                <Icon name="play-circle-filled" type="material" color="#FDCC0D" size={40} />
              </View>
              <Image
                style={{
                  position: 'relative',
                  width: '100%',
                  borderRadius: 8,
                  paddingTop: '56.25%',
                }}
                source={{ uri: item.uri }}
              />
            </View>
          </TouchableOpacity>
        );
      }
    };

    return (
      <>
        <View
          style={{
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'center',
          }}>
          {!store.loading ? (
            <>
              {Platform.OS === 'web' ? (
                <View style={{ width: '90%', height: '90%', margin: 25 }}>
                  <Slider {...settings}>
                    {store.carouselItems.map((item, index) =>
                      _renderItem({ item: item, index: index })
                    )}
                  </Slider>
                </View>
              ) : (
                <>
                  <Carousel
                    layout={'stack'}
                    data={store.carouselItems}
                    sliderWidth={DISPLAY_WIDTH}
                    itemWidth={200}
                    renderItem={_renderItem}
                  />
                </>
              )}
              {store.imageModal && (
                <Overlay
                  isVisible={store.imageModal}
                  onBackdropPress={() => (store.imageModal = false)}>
                  {store.carouselItems[store.index] && (
                    <Image
                      style={{
                        width: DISPLAY_WIDTH - 20,
                        aspectRatio:
                          store.carouselItems[store.index].width /
                          store.carouselItems[store.index].height,
                      }}
                      source={{ uri: store.carouselItems[store.index].uri }}
                    />
                  )}
                </Overlay>
              )}
            </>
          ) : (
            <View
              style={{
                flex: 1,
                paddingTop: 20,
                paddingBottom: 20,
                marginBottom: 10,
                justifyContent: 'center',
                backgroundColor: 'white',
                alignItems: 'center',
              }}>
              <Spinner size="giant" />
            </View>
          )}
        </View>
      </>
    );
  }
);
