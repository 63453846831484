import { observable } from 'mobx';
import { ITitle } from 'src/model/titles';
import { IBackdrop } from '../moviedetail/carouselstore';
import Axios from 'axios';
import firebase from 'firebase';
import { profilestore } from '../../profiletab/profilestore';
import { watchliststore } from '../../watchlist/watchliststore';
import { moviestore } from '../moviestore';
import i18next from 'i18next';

class FeaturedStore {
  @observable title: ITitle | undefined;
  @observable image = {} as IBackdrop;
  @observable mediaType = '';

  async init() {
    let featuredTitle;
    const response = await firebase.firestore().collection('featured').limit(1).get();
    if (!response.empty) {
      featuredTitle = response.docs[0].data() as { mediaType: string; movieId: string };
      this.mediaType = featuredTitle.mediaType;
    }
    if (featuredTitle) {
      const imageResponse = await Axios.get(
        `https://nextjs-movierater.now.sh/api/media?id=${featuredTitle.movieId}&movieType=${featuredTitle.mediaType}&mediaType=images`
      );
      if (imageResponse && imageResponse.data) {
        this.image = imageResponse.data.backdrops[0];
      }
      if (profilestore.authUser) {
        moviestore.bookMarked = await watchliststore.checkInWatchlist(featuredTitle.movieId);
      }
      const titleResponse = await Axios.get(
        `https://nextjs-movierater.now.sh/api/titles?titleId=${featuredTitle.movieId}&media_type=${featuredTitle.mediaType}&language=${i18next.language}`
      );
      if (titleResponse && titleResponse.data) {
        this.title = titleResponse.data;
      }
    }
  }
}

export const featuredstore = new FeaturedStore();
