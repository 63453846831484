import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { MovieSlider } from './movieslider/movieslider';
import { ScrollView } from 'react-native-gesture-handler';
import { RefreshControl, Image } from 'react-native';
import { moviestore } from './moviestore';
import firebase from 'firebase';
import Axios from 'axios';
import { Layout, TopNavigation, Text, useStyleSheet, StyleService } from '@ui-kitten/components';
import * as Analytics from 'expo-firebase-analytics';
import { profilestore } from '../profiletab/profilestore';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import { FeaturedBanner } from './featured/featuredbanner';
import ratingboxlogo from './assets/logo.png';
import { ratingstore } from 'src/ratingscreen/ratingstore';

export const HomeScreen = withTranslation()(
  observer(({ navigation, t }: any) => {
    const store = moviestore;
    const styles = useStyleSheet(themedStyles);

    useEffect(() => {
      const initStore = async () => {
        const response = await firebase
          .firestore()
          .collection('ratings')
          .orderBy('created_at', 'desc')
          .limit(20)
          .get();
        await Analytics.logEvent('Hompage', {
          name: 'Hompage',
          username: profilestore.user?.username,
          purpose: 'Opens the internal settings',
        });
        store.loadingLastRatedTitles = true;
        const movieIds: { movieId: number; media_type: string; rating: number }[] = [];
        response.docs.forEach((doc) => {
          if (!movieIds.find((movie) => movie.movieId === doc.data().movieId)) {
            movieIds.push({
              movieId: doc.data().movieId,
              media_type: doc.data().media_type,
              rating: doc.data().rating,
            });
          }
        });
        for (const ratingDoc of movieIds) {
          try {
            const response = await Axios.get(
              `https://nextjs-movierater.now.sh/api/titles?titleId=${ratingDoc.movieId}&media_type=${ratingDoc.media_type}&excludedetail=true&language=${i18next.language}`
            );
            if (response && response.data) {
              response.data.media_type = ratingDoc.media_type;
              store.lastRatedTitles?.push(response.data);
            }
          } catch (error) {
            console.log(error);
          }
        }
        store.loadingLastRatedTitles = false;
      };
      initStore();
    }, []);
    return (
      <>
        <TopNavigation
          alignment="center"
          style={styles.primaryBottomBorder}
          leftControl={
            <>
              <Text category="h4">RATING </Text>
              <Text category="h4" style={styles.primaryColor}>
                BOX
              </Text>
              <Image
                style={{ width: 30, height: 28 }}
                resizeMode="contain"
                source={ratingboxlogo}
              />
            </>
          }
        />
        <Layout level="1" style={{ flex: 1 }}>
          <ScrollView
            refreshControl={
              <RefreshControl
                refreshing={store.refreshing}
                onRefresh={() => (store.refreshing = true)}
              />
            }>
            <MovieSlider
              headline={t('homepage.headlinepopular')}
              containerStyle={{ marginBottom: 10 }}
              navigation={navigation}
              requestUrl={`https://nextjs-movierater.now.sh/api/titles?time_window=day&media_type=all&language=${i18next.language}`}
            />
            <FeaturedBanner navigation={navigation} />
            <MovieSlider
              navigation={navigation}
              headline={t('homepage.headlineshow')}
              requestUrl={`https://nextjs-movierater.now.sh/api/titles?time_window=week&media_type=tv&language=${i18next.language}`}
              containerStyle={{ marginBottom: 10 }}
            />
            <MovieSlider
              headline={t('homepage.headlinemovies')}
              navigation={navigation}
              requestUrl={`https://nextjs-movierater.now.sh/api/titles?time_window=week&media_type=movie&language=${i18next.language}`}
              containerStyle={{ marginBottom: 10 }}
            />
            <MovieSlider
              headline={t('homepage.lastreviewed')}
              navigation={navigation}
              containerStyle={{ marginBottom: 10 }}
            />
          </ScrollView>
        </Layout>
      </>
    );
  })
);
const themedStyles = StyleService.create({
  primaryColor: {
    color: 'color-primary-default',
  },
  primaryBottomBorder: {
    borderBottomWidth: 3,
    minHeight: 30,
    borderBottomColor: 'color-primary-default',
  },
});
